import React, { useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import stripeStyles from '../styles/stripe';

const StripeTokenWidget = ({
  isDisabled,
  onSuccess,
  onError,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (e) => {
    setErrorMessage(null);
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const result = await stripe.createToken(
      elements.getElement('card'),
      { currency: 'usd' }
    );

    const error = get(result, 'error');
    const token = get(result, 'token');

    if (token) {
      onSuccess && onSuccess(token);
    }
    if (error) {
      console.log('error:', error)
      onError && onError(error);
      setErrorMessage(get(error, 'message'))
      setIsLoading(false);
    }
  }

  const buttonMessage = 'Create payment method';

  return (
    <>
      <div css={stripeStyles.flexboxOnDesktop}>
        <div css={stripeStyles.cardElement}>
          <CardElement options={{ disabled: isDisabled || isLoading }} />
        </div>
        <div css={stripeStyles.inlineOnDesktop}>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {buttonMessage}
          </Button>
        </div>
      </div>
      {errorMessage ? (
        <Typography>{errorMessage}</Typography>
      ) : null}
    </>
  );
}

export default StripeTokenWidget;

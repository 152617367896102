import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { getMyProfile, createExternalAccount } from '../api/users';
import withAlert from '../hoc/withAlert';
import {
  setAlert,
} from '../redux/alerts';
import {
  getIsLoadingStripe,
  setIsLoadingStripe,
  setStripeExternalAccounts,
} from '../redux/stripe';
import StripeTokenWidget from './StripeTokenWidget';

import Button from '@material-ui/core/Button';

const ExternalCardWidget = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [shouldReload, setShouldReload] = useState(null);

  const isLoadingStripe = useSelector(getIsLoadingStripe);

  useEffect(() => {
    const fetchStripeData = async () => {
      // TODO: Make endpoint only for Stripe external accounts
      const profileFromDB = await getMyProfile();
      const externalAccountsData = get(profileFromDB, 'stripeAccount.externalAccounts.data', []);
      dispatch(setStripeExternalAccounts(externalAccountsData));
      dispatch(setIsLoadingStripe(false));
    }
    if (shouldReload !== null) {
      fetchStripeData();
    }
  }, [shouldReload]);

  const onClickOpen = () => setIsOpen(true);

  const onTokenSuccess = async (token) => {
    dispatch(setIsLoadingStripe(true));
    const externalAccount = await createExternalAccount(token.id);
    if (externalAccount) {
      dispatch(setAlert('Card saved successfully!'))
      setShouldReload(!shouldReload);
    } else {
      dispatch(setIsLoadingStripe(false));
    }
    setIsOpen(false);
  }

  const onTokenError = (error) => {
    console.log('error:', error)
  }

  return isOpen ? (
    <StripeTokenWidget
      onSuccess={onTokenSuccess}
      onError={onTokenError}
      isDisabled={isLoadingStripe}
    />
  ) : (
    <Button variant="outlined" onClick={onClickOpen}>
      Add new card
    </Button>
  );
}

export default withAlert(ExternalCardWidget);

const SET_IS_LOADING = 'AUTH/SET_AUTH_IS_LOADING';
const SET_USER_DISPLAY_NAME = 'AUTH/SET_USER_DISPLAY_NAME';
const SET_USER_ID = 'AUTH/SET_USER_ID';
const SET_USERNAME = 'AUTH/SET_USERNAME';
const SET_BIO = 'AUTH/SET_BIO';
const SET_IS_LOADING_PROFILE = 'AUTH/SET_IS_LOADING_PROFILE';
const SET_IS_LOADING_USERNAME = 'AUTH/SET_IS_LOADING_USERNAME';
const SET_IS_LOADING_BIO = 'AUTH/SET_IS_LOADING_BIO';
const SET_STRIPE_ACCOUNT_ID = 'AUTH/SET_STRIPE_ACCOUNT_ID';
const SET_STRIPE_CUSTOMER_ID = 'AUTH/SET_STRIPE_CUSTOMER_ID';

const initialState = {
  isLoading: true,
  userDisplayName: null,
  userId: null,
  username: null,
  bio: null,
  isLoadingProfile: null,
  isLoadingUsername: null,
  isLoadingBio: null,
  stripeAccountId: null,
  stripeCustomerId: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_USER_DISPLAY_NAME:
      return {
        ...state,
        userDisplayName: payload,
      }
    case SET_USER_ID:
      return {
        ...state,
        userId: payload,
      }
    case SET_USERNAME:
      return {
        ...state,
        username: payload,
      }
    case SET_BIO:
      return {
        ...state,
        bio: payload,
      }
    case SET_IS_LOADING_PROFILE:
      return {
        ...state,
        isLoadingProfile: payload,
      }
    case SET_IS_LOADING_USERNAME:
      return {
        ...state,
        isLoadingUsername: payload,
      }
    case SET_IS_LOADING_BIO:
      return {
        ...state,
        isLoadingBio: payload,
      }
    case SET_STRIPE_ACCOUNT_ID:
      return {
        ...state,
        stripeAccountId: payload,
      }
    case SET_STRIPE_CUSTOMER_ID:
      return {
        ...state,
        stripeCustomerId: payload,
      }
    default:
      return state;
  }
}

export function setAuthIsLoading(isLoading) {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}

export function setUserDisplayName(userDisplayName) {
  return {
    type: SET_USER_DISPLAY_NAME,
    payload: userDisplayName,
  }
}

export function setUserId(userId) {
  return {
    type: SET_USER_ID,
    payload: userId,
  }
}

export function setUsername(username) {
  return {
    type: SET_USERNAME,
    payload: username,
  }
}

export function setBio(bio) {
  return {
    type: SET_BIO,
    payload: bio,
  }
}

export function setIsLoadingProfile(isLoading) {
  return {
    type: SET_IS_LOADING_PROFILE,
    payload: isLoading,
  }
}

export function setIsLoadingUsername(isLoading) {
  return {
    type: SET_IS_LOADING_USERNAME,
    payload: isLoading,
  }
}

export function setIsLoadingBio(isLoading) {
  return {
    type: SET_IS_LOADING_BIO,
    payload: isLoading,
  }
}

export function setStripeAccountId(stripeAccountId) {
  return {
    type: SET_STRIPE_ACCOUNT_ID,
    payload: stripeAccountId,
  }
}

export function setStripeCustomerId(stripeCustomerId) {
  return {
    type: SET_STRIPE_CUSTOMER_ID,
    payload: stripeCustomerId,
  }
}

export function getAuthIsLoading(state) {
  return state.auth.isLoading;
}

export function getUserDisplayName(state) {
  return state.auth.userDisplayName;
}

export function getUserId(state) {
  return state.auth.userId;
}

export function getUsername(state) {
  return state.auth.username;
}

export function getBio(state) {
  return state.auth.bio;
}

export function getIsLoadingProfile(state) {
  return state.auth.isLoadingProfile;
}

export function getIsLoadingUsername(state) {
  return state.auth.isLoadingUsername;
}

export function getIsLoadingBio(state) {
  return state.auth.isLoadingBio;
}

export function getStripeAccountId(state) {
  return state.auth.stripeAccountId;
}

export function getStripeCustomerId(state) {
  return state.auth.stripeCustomerId;
}

import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import { getCampaign } from '../redux/campaigns';

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import pageStyles from '../styles/page';
import formStyles from '../styles/form';

const CampaignInfo = ({
  campaignId,
  cssProp,
}) => {
  const campaign = useSelector(getCampaign(campaignId));
  const description = get(campaign, 'description');
  const fundingGoal = get(campaign, 'fundingGoal');
  const fundsTotal = get(campaign, 'fundsTotal');
  const stake = get(campaign, 'stake');

  return (
    <div css={cssProp}>
      <div css={formStyles.field}>
        <Typography>
          {`Goal: $${fundingGoal}`}
        </Typography>
        <Typography>
          {`Creator stake: $${stake}`}
        </Typography>
        <Typography>
          {`Funded so far: ${fundsTotal || fundsTotal === 0 ? `$${fundsTotal}` : 'loading...'}`}
        </Typography>
      </div>
      {description ? (
        <div css={pageStyles.item}>
          {description.split('\n').filter(paragraph => !!paragraph).map((paragraph, i) => (
            <Typography key={i}>{paragraph}</Typography>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default CampaignInfo;

import { css } from '@emotion/core';
import indigo from '@material-ui/core/colors/indigo';

const link = css`
  cursor: pointer;
  color: ${indigo[500]};
`;

const overflow = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const navBarLink = css`
  color: white;
  margin-right: 16px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const buttonLink = css`
  &:hover {
    text-decoration: none;
  }
`;

export default {
  link,
  overflow,
  navBarLink,
  buttonLink,
};

import React from 'react';
import { BREAKPOINTS } from '../utils/layout'

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';

const headerContainer = css`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin-bottom: 16px;
  }
`;

const seeMoreTextContainer = css`
  margin-left: 16px;
`;

const prevNextContainer = css`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const chevronButton = css`
  padding: 0;
  min-width: unset;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    padding: 6px 8px;
  }
`;

const CampaignPreviewShelfTitle = ({
  title,
  subtitle,
  seeMoreText,
  seeMoreHref,
  campaignListLength,
  displayOffset,
  setDisplayOffset,
}) => {
  const isPrevNextDisplayed = campaignListLength > 3;
  const isPrevEnabled = displayOffset > 0;
  const isNextEnabled = displayOffset < campaignListLength - 3;

  const onClickPrev = () => {
    if (isPrevEnabled) {
      setDisplayOffset(displayOffset - 1)
    }
  }

  const onClickNext = () => {
    if (isNextEnabled) {
      setDisplayOffset(displayOffset + 1)
    }
  }

  return (
    <div css={headerContainer}>
      <div>
        <Typography variant="h5">
          {title}
        </Typography>
        <Typography variant="subtitle2">
          {subtitle}
        </Typography>
      </div>
      <div css={seeMoreTextContainer}>
        <Link href={seeMoreHref}>
          <Typography variant="body2">
            {seeMoreText}
          </Typography>
        </Link>
      </div>
      {isPrevNextDisplayed ? (
        <div css={prevNextContainer}>
          <Button disabled={!isPrevEnabled} css={chevronButton} onClick={onClickPrev}>
            <ChevronLeftIcon
              fontSize="large"
            />
          </Button>
          <Button disabled={!isNextEnabled} css={chevronButton} onClick={onClickNext}>
            <ChevronRightIcon
              fontSize="large"
            />
          </Button>
        </div>
      ) : null}
    </div>
    );
}

export default CampaignPreviewShelfTitle;

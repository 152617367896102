import React from 'react';
import { useSelector } from 'react-redux';
import { getDraftCampaign } from '../redux/draftCampaign';
import StandardLayout from '../layout/StandardLayout';
import withAuth from '../hoc/withAuth';
import withDraftCampaign from '../hoc/withDraftCampaign';
import DraftCampaignInfo from '../components/DraftCampaignInfo';
import { DACKIE_HELP_EMAIL } from '../utils/contact';

import Typography from '@material-ui/core/Typography';

import campaignStyles from '../styles/campaign';

const ReviewCampaignPage = () => {
  const draftCampaign = useSelector(getDraftCampaign);

  return (
    <StandardLayout>
      {draftCampaign ? (
        <DraftCampaignInfo
          campaign={draftCampaign}
          cssProp={campaignStyles.draftCampaignInfo}
        />
      ) : null}
      <Typography>
        Your campaign is under review!
      </Typography>
      <Typography>
        {`Contact ${DACKIE_HELP_EMAIL} if you'd like to make changes.`}
      </Typography>
      <Typography>
        Once your campaign is approved, we'll let you know and you can make a stake payment.
      </Typography>
      <Typography>
        The campaign will be published once you make the stake payment.
      </Typography>
    </StandardLayout>
  )
}

export default withAuth(withDraftCampaign(ReviewCampaignPage));
import React, { useEffect } from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import * as firebase from "firebase/app";
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import {
  setAuthIsLoading,
  setUserDisplayName,
  setUserId,
  setStripeAccountId,
  setStripeCustomerId,
} from './redux/auth';
import { getUserById } from './api/users';
import './App.css';
import NavBar from "./components/NavBar";
import HomePage from "./pages/HomePage";
import PrivacyPolicy from './pages/PrivacyPolicyPage';
import TermsOfService from './pages/TermsOfServicePage';
import CampaignPage from './pages/CampaignPage';
import FirebaseUIPage from './pages/FirebaseUIPage';
import MyCampaignsPage from './pages/MyCampaignsPage';
import FundedCampaignsPage from './pages/FundedCampaignsPage';
import CreateCampaignPage from './pages/CreateCampaignPage';
import ReviewCampaignPage from './pages/ReviewCampaignPage';
import PublishCampaignPage from './pages/PublishCampaignPage';
import ProfilePage from './pages/ProfilePage';
import AboutPage from './pages/AboutPage';
import StatsPage from './pages/StatsPage';
import RateCampaignPage from './pages/RateCampaignPage';

import { ThemeProvider } from '@material-ui/core';
import theme from './styles/theme';
import PublicProfilePage from './pages/PublicProfilePage';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // console.log('user:', user);
        const displayName = get(user, 'displayName');
        const userId = get(user, 'uid');
        dispatch(setUserDisplayName(displayName));
        dispatch(setUserId(userId));
        const userFromDB = await getUserById(userId);
        const stripeAccountId = get(userFromDB, 'stripeAccountId');
        if (stripeAccountId) {
          dispatch(setStripeAccountId(stripeAccountId));
        }
        const stripeCustomerId = get(userFromDB, 'stripeCustomerId');
        if (stripeCustomerId) {
          dispatch(setStripeCustomerId(stripeCustomerId));
        }
      } else {
        // console.log('no user sry')
        dispatch(setUserDisplayName(null));
        dispatch(setUserId(null));
        dispatch(setStripeAccountId(null));
        dispatch(setStripeCustomerId(null));
      }
      dispatch(setAuthIsLoading(false));
    });
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/privacypolicy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/termsofservice">
          <TermsOfService />
        </Route>
        <Route path="/login">
          <FirebaseUIPage />
        </Route>
        <Route path="/campaign">
          <CampaignPage />
        </Route>
        <Route exact path="/campaigns">
          <MyCampaignsPage />
        </Route>
        <Route exact path="/campaigns/funded">
          <FundedCampaignsPage />
        </Route>
        <Route
          exact
          path="/campaigns/create"
          component={CreateCampaignPage}
        />
        <Route
          path="/campaigns/edit/:id"
          component={CreateCampaignPage}
        />
        <Route
          path="/campaigns/review/:id"
          component={ReviewCampaignPage}
        />
        <Route
          path="/campaigns/payment/:id"
          component={PublishCampaignPage}
        />
        <Route exact path="/profile">
          <ProfilePage />
        </Route>
        <Route path="/profile/:id"
          component={PublicProfilePage}
        />
        <Route exact path="/about">
          <AboutPage />
        </Route>
        <Route exact path="/stats">
          <StatsPage />
        </Route>
        <Route
          path="/ratings/:id"
          component={RateCampaignPage}
        />
      </Switch>
      <style>
        {`
          :global(body) {
            margin: 0;
          }
          html {
            height: 100%;
          }
          body {
            background-color: #F7F7F7;
            height: 100%;
          }
          #root {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
          nav {
            text-align: center;
          }
          a {
            color: #067df7;
            text-decoration: none;
            font-size: 13px;
          }
          * { 
            box-sizing: border-box;
          }
        `}
      </style>
    </ThemeProvider>
  )
}

export default App;

import React from 'react';
import frontPageImage from '../images/frontpage.jpg'
import { BREAKPOINTS } from '../utils/layout';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import textStyles from '../styles/text';

const CALL_TO_ACTION_CARD_WIDTH_MOBILE = 300;
const CALL_TO_ACTION_CARD_WIDTH_DESKTOP = 500;

const frontPageShelfContainer = css`
  height: 300px;
  background-image: url(${frontPageImage});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    height: 500px;
  }
`;

const frontPageContentContainer = css`
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1020px) {
    width: 1020px;
    margin: auto;
  }
`;

const callToActionCard = css`
  width: ${CALL_TO_ACTION_CARD_WIDTH_MOBILE}px;
  height: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: ${CALL_TO_ACTION_CARD_WIDTH_DESKTOP}px;
    height: 400px;
    padding: 32px;
  }
`;

const subtitleText = css`
  margin-bottom: 8px;
`;

const callToActionButton = css`
  align-self: center;
`;

const FrontPageShelf = () => {
  return (
    <div css={frontPageShelfContainer}>
      <div css={frontPageContentContainer}>
        <Card css={callToActionCard}>
          <Typography variant="h4">
            Crowdfunding with skin in the game
          </Typography>
          <div>
            <Typography variant="h6" css={subtitleText}>
              Creators are invested in finishing their campaign and their project
            </Typography>
            <Typography variant="h6">
              Funders are invested in successful and unsuccessful campaigns
            </Typography>
          </div>
          <Link href="/campaigns/create" css={[callToActionButton, textStyles.buttonLink]}>
            <Button variant="outlined">
              Get started
            </Button>
          </Link>
        </Card>
      </div>
    </div>
  );
}

export default FrontPageShelf;

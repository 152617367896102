import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../layout/Footer';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';

const content = css`
  flex: 1 0 auto;
`;

const HomePageLayout = ({
  children,
}) => {
  return (
    <>
      <div css={content}>
        {children}
      </div>
      <Footer />
    </>
  );
};

HomePageLayout.propTypes = {
  children: PropTypes.any,
};

export default HomePageLayout;

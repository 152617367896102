import get from "lodash/get";
import reduce from "lodash/reduce";

const SET_HAS_ACHIEVEDS = 'ACHIEVEMENTS/SET_HAS_ACHIEVEDS';
const SET_HAS_ACHIEVED = 'ACHIEVEMENTS/SET_HAS_ACHIEVED'
const SET_STATIC_ACHIEVEMENTS = 'ACHIEVEMENTS/SET_STATIC_ACHIEVEMENTS';
const SET_UNSEEN_ACHIEVEMENT_KEYS = 'ACHIEVEMENTS/SET_UNSEEN_ACHIEVEMENT_KEYS';
const REMOVE_UNSEEN_ACHIEVEMENT_KEY = 'ACHIEVEMENTS/REMOVE_UNSEEN_ACHIEVEMENT_KEY';

const initialState = {
  staticData: {}, // { hasCreatedCampaign: { name: 'Creator', description: 'Create a campaign' }}
  staticList: [], // [ hasCreatedCampaign, collectPayout1 ] (in order)
  hasAchieved: {}, // { hasCreatedCampaign: true, collectPayout1: true }
  unseenList: [] // [ hasCreatedCampaign, collectPayout1 ]
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_HAS_ACHIEVEDS:
      return {
        ...state,
        hasAchieved: {
          ...state.data,
          ...payload,
        },
      }
    case SET_HAS_ACHIEVED:
      return {
        ...state,
        hasAchieved: {
          ...state.hasAchieved,
          [payload]: true,
        }
      }
    case SET_STATIC_ACHIEVEMENTS:
      return {
        ...state,
        staticData: payload.data,
        staticList: payload.list,
      }
    case SET_UNSEEN_ACHIEVEMENT_KEYS:
      return {
        ...state,
        unseenList: payload,
      }
    case REMOVE_UNSEEN_ACHIEVEMENT_KEY:
      const index = get(state, 'unseenList').indexOf(payload);
      return {
        ...state,
        unseenList: [
          ...state.unseenList.slice(0, index),
          ...state.unseenList.slice(index + 1),
        ]
      }
    default:
      return state;
  }
}

export const setHasAchieveds = (achievedKeysList) => {
  const hasAchievedByKey = reduce(achievedKeysList, (prev, curr) => {
    return {
      ...prev,
      [curr]: true,
    };
  }, {});
  return {
    type: SET_HAS_ACHIEVEDS,
    payload: hasAchievedByKey,
  }
}

export const setHasAchieved = (key) => {
  return {
    type: SET_HAS_ACHIEVED,
    payload: key,
  }
}

export const setStaticAchievements = (staticAchievements) => {
  return {
    type: SET_STATIC_ACHIEVEMENTS,
    payload: {
      data: get(staticAchievements, 'data', {}),
      list: get(staticAchievements, 'list', [])
    }
  }
}

export const setUnseenAchievementKeys = (keyList) => {
  return {
    type: SET_UNSEEN_ACHIEVEMENT_KEYS,
    payload: keyList,
  }
}

export const removeUnseenAchievementKey = (achievementKey) => {
  return {
    type: REMOVE_UNSEEN_ACHIEVEMENT_KEY,
    payload: achievementKey,
  }
}

export const getHasAchieved = (key) => (state) => {
  return get(state, `achievements.hasAchieved[${key}]`);
}

export const getStaticAchievementKeys = (state) => {
  return get(state, `achievements.staticList`);
}

export const getStaticAchievement = (key) => (state) => {
  return get(state, `achievements.staticData[${key}]`, null);
}

export const getNextUnseenAchievementKey = (state) => {
  return get(state, 'achievements.unseenList[0]', null);
}

export const getUnseenAchievementKeys = (state) => {
  return get(state, 'achievements.unseenList');
}

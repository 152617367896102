import React from 'react';
import get from 'lodash/get'

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const CREATED_CAMPAIGN_TABLE_COLUMNS = [
  { key: 'name', title: 'Name' },
  { key: 'amount', title: 'Amount pledged' },
  { key: 'fundsTotal', title: 'Total funds' },
  { key: 'setupCompletedTimestamp', title: 'Date' },
];

const PublicProfileFundedCampaignsTable = ({
  campaigns
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {CREATED_CAMPAIGN_TABLE_COLUMNS.map(column => (
              <TableCell key={column.key}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map((campaign, i) => {
            const name = get(campaign, 'name');
            const amount = get(campaign, 'amount');
            const fundsTotal = get(campaign, 'fundsTotal');
            const fundDate = get(campaign, 'setupCompletedTimestamp');
            const data = {
              name,
              amount: amount ? `$${amount}` : `N/A`,
              fundsTotal: fundsTotal ? `$${fundsTotal}` : "$0",
              setupCompletedTimestamp: fundDate ? new Date(fundDate).toLocaleDateString() : "N/A",
            };
            return (
              <TableRow key={i}>
                {CREATED_CAMPAIGN_TABLE_COLUMNS.map(column => (
                  <TableCell key={column.key}>
                    {data[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PublicProfileFundedCampaignsTable;

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { db } from '../firebase';
import get from 'lodash/get';
import { getUIReadyCampaign } from '../utils/campaignModel';

export async function getNewCampaigns() {
  try {
    const res = await firebase.functions().httpsCallable('getNewCampaigns')();
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getTopActiveCampaigns() {
  try {
    const res = await firebase.functions().httpsCallable('getTopActiveCampaigns')();
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getMyCampaigns(offset = 0, limit = 4) {
  try {
    const res = await firebase.functions().httpsCallable('getMyCampaigns')({
      offset,
      limit,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return [];
  }
}

export async function getCampaignById(campaignId, options) {
  const withUserFunds = get(options, 'withUserFunds');
  try {
    const res = await firebase.functions().httpsCallable('getCampaignById')({
      campaignId,
      withUserFunds,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getCampaignForEditing(id) {
  return db.collection('campaigns').doc(id).get().then((doc) => {
    if (doc.exists) {
      const campaign = doc.data();
      const status = get(campaign, 'status');
      if (status === 'review' || status === 'approved' || status === 'published' || status === 'archived') {
        return campaign;
      } else {
        return getUIReadyCampaign(campaign);
      }
    } else {
      return {};
    }
  }).catch(err => {
    console.log(err);
    return null;
  })
};

export async function createPaymentIntentForStake(campaignId) {
  if (!campaignId) {
    return {};
  }
  try {
    const res = await firebase.functions().httpsCallable('createStakePaymentIntent')({
      campaignId,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function createSetupIntentForFunds(campaignId, amount, currency) {
  if (!campaignId) {
    return {};
  }
  try {
    const res = await firebase.functions().httpsCallable('createFundsSetupIntent')({
      campaignId,
      amount,
      currency,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function saveCampaign(campaign) {
  try {
   const res = await firebase.functions().httpsCallable('saveCampaign')({
     campaign,
   });
   const savedCampaign = get(res, 'data');
   return savedCampaign;
  } catch (err) {
    return false;
  }
};

export async function submitCampaignForReview(campaign) {
  try {
    const res = await firebase.functions().httpsCallable('submitCampaignForReview')({
      campaign,
    }); 
    const savedCampaign = get(res, 'data');
    return savedCampaign;
  } catch (err) {
    return false;
  }
}

export async function getUserFunds(offset = 0, limit = 4) {
  try {
    const res = await firebase.functions().httpsCallable('getUserFunds')({
      offset,
      limit,
    });
    const userFunds = get(res, 'data');
    return userFunds;
  } catch (err) {
    return [];
  }
}

export async function collectPayout(fundsId) {
  try {
    const res = await firebase.functions().httpsCallable('collectPayout')({
      fundsId,
    });
    const success = get(res, 'data');
    return success;
  } catch (err) {
    return null;
  }
}

export async function getCampaignFunds(campaignId, offset = 0, limit = 4) {
  try {
    const res = await firebase.functions().httpsCallable('getCampaignFunds')({
      campaignId,
      offset,
      limit,
    });
    const funds = get(res, 'data');
    return funds;
  } catch (err) {
    return [];
  }
}

export const getCampaignFundsHandler = (campaignId) => (offset = 0, limit = 4) => {
  return getCampaignFunds(campaignId, offset, limit);
}

export default {
  getMyCampaigns,
  getTopActiveCampaigns,
  getCampaignById,
  getCampaignForEditing,
  createPaymentIntentForStake,
  createSetupIntentForFunds,
  saveCampaign,
  submitCampaignForReview,
  getUserFunds,
  collectPayout,
  getCampaignFunds,
}

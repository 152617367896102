import React from 'react';
import get from 'lodash/get';
import StandardLayout from '../layout/StandardLayout';
import { getMyCampaigns } from '../api/campaigns';
import withAuth from '../hoc/withAuth';
import {
  getMyCampaignsList,
  setMyCampaigns,
  getMyCampaign,
  getIsLoadingMyCampaigns,
  setIsLoadingMyCampaigns,
} from '../redux/myCampaigns';
import ItemsTable from '../components/ItemsTable';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';

const getButtonText = (campaign) => {
  const status = get(campaign, 'status');
  switch (status) {
    case 'published':
    case 'archived':
      return 'View'
    case 'approved':
      return 'Publish'
    case 'review':
      return 'Check status'
    default:
      return 'Edit';
  }
}

const getButtonHref = (campaign) => {
  const campaignId = get(campaign, 'id')
  const status = get(campaign, 'status');
  switch (status) {
    case 'published':
    case 'archived':
      return `/campaign/${campaignId}`
    case 'approved':
      return `/campaigns/payment/${campaignId}`
    case 'review':
      return `/campaigns/review/${campaignId}`
    default:
      return `/campaigns/edit/${campaignId}`
  }
}

const FIELDS_TO_SHOW = [
  'Name',
  'Stake',
  'Goal',
  'Total Funds',
  'End Date',
  'Funding Status',
];

const actionButtons = [
  {
    fieldKey: '',
    getText: getButtonText,
    getHref: getButtonHref,
  }
];

const formatName = (campaign) => get(campaign, 'name');

const formatStake = (campaign) => {
  const stake = get(campaign, 'stake');
  return `$${stake}`;
}

const formatFundsTotal = (campaign) => {
  const fundsTotal = get(campaign, 'fundsTotal');
  return `$${fundsTotal}`;
}

const formatFundingGoal = (campaign) => {
  const fundingGoal = get(campaign, 'fundingGoal');
  return `$${fundingGoal}`;
}

const formatEndDate = (campaign) => {
  const endDate = get(campaign, 'endDate');
  return endDate ? new Date(endDate).toLocaleDateString() : '';
}

const formatFundingStatus = (campaign) => {
  const status = get(campaign, 'status');
  if (status !== 'archived') {
    const endDate = get(campaign, 'endDate');
    if (!endDate) {
      return 'Not yet published';
    }
    if (endDate > Date.now()) {
      return 'In progress';
    }
  }

  const wasSuccessful = get(campaign, 'wasSuccessful');
  if (!wasSuccessful) {
    const fundsTotal = get(campaign, 'fundsTotal');
    const fundingGoal = get(campaign, 'fundingGoal');
    if (fundsTotal < fundingGoal) {
      return 'Did not meet funding goal';
    }
  }
  const hasBeenRefunded = get(campaign, 'hasBeenRefunded');
  const stakeStatus = hasBeenRefunded ? 'Stake refunded' : 'Stake refunded';

  const fundsCollectionStartedTimestamp = get(campaign, 'fundsCollectionStartedTimestamp');
  const fundsCollectionDate = fundsCollectionStartedTimestamp ? new Date(fundsCollectionStartedTimestamp) : null;
  const fundsStatus = fundsCollectionStartedTimestamp ? `funds collected ${fundsCollectionDate.toLocaleDateString()}` : 'Funds not yet collected';
  
  return `${stakeStatus}, ${fundsStatus}`;
}

const fieldProcessingFuncs = {
  'Name': formatName,
  'Stake': formatStake,
  'Goal': formatFundingGoal,
  'Total Funds': formatFundsTotal,
  'End Date': formatEndDate,
  'Funding Status': formatFundingStatus,
}

const MyCampaigns = () => {
  return (
    <StandardLayout>
      <ItemsTable
        titleText="My Campaigns"
        fetchItemsFunc={getMyCampaigns}
        getItemIdsSelector={getMyCampaignsList}
        setItemsDispatch={setMyCampaigns}
        getItemByIdSelector={getMyCampaign}
        setIsLoadingDispatch={setIsLoadingMyCampaigns}
        getIsLoadingSelector={getIsLoadingMyCampaigns}
        fieldsToShow={FIELDS_TO_SHOW}
        fieldProcessingFuncs={fieldProcessingFuncs}
        actionButtons={actionButtons}
        pageSize={4}
        emptyTableMessage={'Click here to start a campaign.'}
        emptyTableButtonText={'Create Campaign'}
        emptyTableButtonHref={'/campaigns/create'}
      />
    </StandardLayout>
  );
};

export default withAuth(MyCampaigns);

import {
  getUIDateFromJSDate,
  getUITimeFromJSDate,
  getJSDateFromUIDateAndTime,
} from './format';
import get from 'lodash/get';

// TODO: See what I can clean up given that campaign saving is validated on backend

export const getDefaultDates = () => {
  const today = new Date();
  today.setMinutes(today.getMinutes() + 60);
  today.setMinutes(0);
  const todaysDate = getUIDateFromJSDate(today);
  const todaysTime = getUITimeFromJSDate(today);
  const thirtyDays = new Date();
  thirtyDays.setDate(today.getDate() + 30);
  const thirtyDaysDate = getUIDateFromJSDate(thirtyDays);
  return {
    todaysDate, todaysTime, thirtyDaysDate
  }
}

export const CAMPAIGN_UI_KEYS = {
  name: 'name',
  description: 'description',
  fundingGoal: 'fundingGoal', // Needs default value for controlled input
  fundingGoalCurrency: 'fundingGoalCurrency',
  stake: 'stake', // Needs default value for controlled input
  stakeCurrency: 'stakeCurrency',
  duration: 'duration',
  tagline: 'tagline',
  ratingPeriod: 'ratingPeriod',
  startDate: 'startDate',
  startTime: 'startTime',
  endDate: 'endDate',
  endTime: 'endTime',
}

export const UI_DEFAULTS = {
  [CAMPAIGN_UI_KEYS.name]: '',
  [CAMPAIGN_UI_KEYS.description]: '',
  [CAMPAIGN_UI_KEYS.fundingGoal]: '',
  [CAMPAIGN_UI_KEYS.fundingGoalCurrency]: 'usd',
  [CAMPAIGN_UI_KEYS.stake]: '',
  [CAMPAIGN_UI_KEYS.stakeCurrency]: 'usd',
  [CAMPAIGN_UI_KEYS.duration]: 30,
  [CAMPAIGN_UI_KEYS.tagline]: '',
  [CAMPAIGN_UI_KEYS.ratingPeriod]: 30,
  // [CAMPAIGN_UI_KEYS.startDate]: getDefaultDates().todaysDate,
  // [CAMPAIGN_UI_KEYS.startTime]: getDefaultDates().todaysTime,
  // [CAMPAIGN_UI_KEYS.endDate]: getDefaultDates().thirtyDaysDate,
  // [CAMPAIGN_UI_KEYS.endTime]: getDefaultDates().todaysTime,
}

export const SPECIAL_CONVERSION_KEYS = {
  // [CAMPAIGN_UI_KEYS.startDate]: true,
  // [CAMPAIGN_UI_KEYS.startTime]: true,
  // [CAMPAIGN_UI_KEYS.endDate]: true,
  // [CAMPAIGN_UI_KEYS.endTime]: true,
}

export const INPUT_LIMITS = {
  [CAMPAIGN_UI_KEYS.name]: 50,
  [CAMPAIGN_UI_KEYS.description]: 2000,
  [CAMPAIGN_UI_KEYS.tagline]: 100,
}

export const MIN_DURATION = 1;
export const MAX_DURATION = 60;
export const MIN_FUNDING_GOAL = 1;
export const MAX_FUNDING_GOAL = 1000;
export const MIN_STAKE = 1;
export const MAX_STAKE = 200;
export const MIN_RATING_PERIOD = 1;
export const MAX_RATING_PERIOD = 90;

export function getAPIReadyCampaign(campaign) {
  const newCampaign = {};
  Object.keys(campaign).forEach(key => {
    if ((campaign[key] || campaign[key] === 0) && CAMPAIGN_UI_KEYS[key] && !SPECIAL_CONVERSION_KEYS[key]) {
      newCampaign[key] = campaign[key];
    }
  });
  const fundingGoal = get(campaign, 'fundingGoal');
  if (fundingGoal) {
    newCampaign.fundingGoal = parseFloat(fundingGoal);
  }
  const stake = get(campaign, 'stake');
  if (stake) {
    newCampaign.stake = parseFloat(stake);
  }
  const uiStartDate = get(campaign, 'startDate');
  const uiStartTime = get(campaign, 'startTime');
  if (uiStartDate && uiStartTime) {
    const startDate = getJSDateFromUIDateAndTime(uiStartDate, uiStartTime);
    newCampaign.startDate = startDate;
  }
  const uiEndDate = get(campaign, 'endDate');
  const uiEndTime = get(campaign, 'endTime');
  if (uiEndDate && uiEndTime) {
    const endDate = getJSDateFromUIDateAndTime(uiEndDate, uiEndTime);
    newCampaign.endDate = endDate;
  }
  return newCampaign;
}

export function getUIReadyCampaign(campaign) {
  const newCampaign = {};
  Object.keys(campaign).forEach(key => {
    if ((campaign[key] || campaign[key] === 0) && CAMPAIGN_UI_KEYS[key] && !SPECIAL_CONVERSION_KEYS[key]) {
      newCampaign[key] = campaign[key];
    }
  });
  let startSeconds = get(campaign, 'startDate.seconds')
  if (startSeconds) {
    const start = new Date(startSeconds * 1000);
    const startDate = getUIDateFromJSDate(start);
    const startTime = getUITimeFromJSDate(start);
    newCampaign.startDate = startDate;
    newCampaign.startTime = startTime;
  }
  let endSeconds = get(campaign, 'endDate.seconds');
  if (endSeconds) {
    const end = new Date(endSeconds * 1000);
    const endDate = getUIDateFromJSDate(end);
    const endTime = getUITimeFromJSDate(end);
    newCampaign.endDate = endDate;
    newCampaign.endTime = endTime;
  }
  return newCampaign;
}

export default {
  getDefaultDates,
  CAMPAIGN_UI_KEYS,
  UI_DEFAULTS,
  SPECIAL_CONVERSION_KEYS,
  INPUT_LIMITS,
  MIN_RATING_PERIOD,
  MAX_RATING_PERIOD,
  getAPIReadyCampaign,
  getUIReadyCampaign,
};
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StandardLayout from '../layout/StandardLayout';
import get from 'lodash/get';
import { getDraftCampaign } from '../redux/draftCampaign';
import withAlert from '../hoc/withAlert';
import withAuth from '../hoc/withAuth';
import withDraftCampaign from '../hoc/withDraftCampaign';
import DraftCampaignInfo from '../components/DraftCampaignInfo';
import StakePayment from '../components/StakePayment';
import { DACKIE_HELP_EMAIL } from '../utils/contact';

import Typography from '@material-ui/core/Typography';

import campaignStyles from '../styles/campaign';

const PublishCampaignPage = () => {
  const match = useRouteMatch('/campaigns/payment/:id')
  const id = get(match, 'params.id');

  const draftCampaign = useSelector(getDraftCampaign);
  const stake = get(draftCampaign, 'stake');
  const stakeCurrency = get(draftCampaign, 'stakeCurrency');

  return (
    <StandardLayout>
      {draftCampaign ? (
        <DraftCampaignInfo
          campaign={draftCampaign}
          cssProp={campaignStyles.draftCampaignInfo}
        />
      ) : null}
      <Typography>
        Your campaign has been approved!
      </Typography>
      <Typography>
        {`Contact ${DACKIE_HELP_EMAIL} if you'd like to make changes.`}
      </Typography>
      <Typography>
        Make your stake payment here.
      </Typography>
      <Typography>
        Once you make the stake payment, your campaign will be published and visible for everyone to see!
      </Typography>
      <StakePayment
        stake={stake}
        stakeCurrency={stakeCurrency}
        campaignId={id}
      />
    </StandardLayout>
  )
}

export default withAuth(withDraftCampaign(withAlert(PublishCampaignPage)));
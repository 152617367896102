export function zeroPad(n) {
  if (typeof n === "number") {
    return n > 9 ? n : `0${n}`;
  } else if (typeof n === "string") {
    return n.length() === 1 ? `0${n}` : n;
  } else {
    return n;
  }
}

export function getUIDateFromJSDate(jsDate) {
  return `${jsDate.getFullYear()}-${zeroPad(jsDate.getMonth() + 1)}-${zeroPad(jsDate.getDate())}`;
}

export function getUITimeFromJSDate(jsDate) {
  return `${zeroPad(jsDate.getHours())}:${zeroPad(jsDate.getMinutes())}`;
}

export function getJSDateFromUIDateAndTime(uiDate, uiTime) {
  const [year, month, date] = uiDate.split('-').map(s => parseInt(s));
  const [hours, minutes] = uiTime.split(':').map(s => parseInt(s))
  return new Date(year, month - 1, date, hours, minutes);
}

export function getTimeRemainingString(timeRemaining) {
  const { days, hours, minutes, seconds } = timeRemaining;
  const daysString = days === 1 ? 'day' : 'days';
  const timeString = `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
  const timeRemainingString = days ? `${days} ${daysString} and ${timeString} hours remaining!` : `${timeString} hours remaining!`;
  return timeRemainingString;
}

export const months = [
  'January', 'February', 'March', 'April',
  'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December'
];

export const currentYear = new Date().getFullYear();
export const years = [...Array(20).keys()].map(i => i + currentYear);

export default {
  zeroPad,
  getUIDateFromJSDate,
  getUITimeFromJSDate,
  getJSDateFromUIDateAndTime,
  getTimeRemainingString,
  months,
  currentYear,
  years,
};

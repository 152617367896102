import { css } from '@emotion/core';

const draftCampaignInfo = css`
  background-color: #e3e3e3;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
`;

export default {
  draftCampaignInfo,
};

import React from 'react';

import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core';

const switchContainer = css`
  display: flex;
`;

const switchLabelOff = css`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const switchLabelOn = css`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SwitchSelector = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <div css={switchContainer}>
      <div css={switchLabelOff}>
        {!value ? (
          <Typography>
            NO
          </Typography>
        ) : null}
      </div>
      <Switch
        checked={!!value}
        onChange={onChange}
        disabled={disabled}
        color='secondary'
      />
      <div css={switchLabelOn}>
        {value ? (
          <Typography>
            YES
          </Typography>
        ) : null}
      </div>
    </div>
  );
}

export default SwitchSelector;

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

export async function getStaticAchievements() {
  try {
    const res = await firebase.functions().httpsCallable('getStaticAchievements')();
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getUnseenAchievements() {
  try {
    const res = await firebase.functions().httpsCallable('getUnseenAchievements')();
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export async function markAchievementsAsSeen(achievementKeyList) {
  try {
    const res = await firebase.functions().httpsCallable('markAchievementsAsSeen')({
      achievementKeyList
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export default {
  getStaticAchievements,
  getUnseenAchievements,
  markAchievementsAsSeen,
};

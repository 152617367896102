import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

const SET_IS_LOADING = 'CAMPAIGN_FUNDS/SET_IS_LOADING';
const SET_CAMPAIGN_FUNDS = 'CAMPAIGN_FUNDS/SET_CAMPAIGN_FUNDS';

const initialState = {
  isLoadingPages: true,
  campaignFundsData: {}, // { fundId: fundObj }
  campaignFundsLists: {}, // { campaignId: { 0: [fundId, fundId] } }
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoadingPages: {
          ...state.isLoadingPages,
          [payload.page]: payload.isLoading,
        },
      }
    case SET_CAMPAIGN_FUNDS:
      return {
        ...state,
        campaignFundsLists: {
          ...state.campaignFundsLists,
          [payload.campaignId]: {
            ...state.campaignFundsLists[payload.campaignId],
            [payload.page]: payload.campaignFundsList,
          }
        },
        campaignFundsData: {
          ...state.campaignFundsData,
          ...payload.campaignFundsData,
        },
      }
    default:
      return state;
  }
}

export const setIsLoadingCampaignFunds = (page, isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: {
      page,
      isLoading,
    }
  }
}

export const setCampaignFundsHandler = (campaignId) => (page, campaignFunds) => {
  const campaignFundsList = map(campaignFunds, funds => funds.id);
  const campaignFundsData = reduce(campaignFunds, (result, funds) => ({ ...result, [funds.id]: funds }), {})
  return {
    type: SET_CAMPAIGN_FUNDS,
    payload: {
      page,
      campaignId,
      campaignFundsList,
      campaignFundsData,
    },
  }
}

export const getIsLoadingCampaignFunds = (page) => (state) => {
  return state.campaignFunds.isLoadingPages[page];
}

export const getCampaignFundsListHandler = (campaignId) => (page) => (state) => {
  return get(state, `campaignFunds.campaignFundsLists[${campaignId}][${page}]`)
}

export function getCampaignFund(fundId) {
  return (state) => {
    return state.campaignFunds.campaignFundsData[fundId];
  }
}

import React from 'react';

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import formStyles from '../styles/form';

const fundQuickView = css`
  width: 300px;
  background-color: '#f5f5f5';
  padding: 20px;
  border-radius: 8px;
`;

const fund = css`
  display: flex;
  justify-content: space-between;
`;

const MyFundsQuickView = ({
  userFunds,
}) => {
  return (
    <div css={[formStyles.field, fundQuickView]}>
      <Typography css={formStyles.field}>
        You have funded this campaign!
      </Typography>
      {userFunds.map(userFund => {
        const { amount, timestamp } = userFund;
        const date = timestamp ? new Date(timestamp) : null;
        return (
          <div
            key={timestamp}
            css={fund}
          >
            <Typography>
              {`$${amount}`}
            </Typography>
            {timestamp ? (
              <Typography>
                {`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}
              </Typography>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default MyFundsQuickView;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { getCampaignPreview } from '../redux/campaignPreviews';

import Link from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import textStyle from '../styles/text';

const CampaignPreviewTableRow = ({
  campaignId,
}) => {
  const campaign = useSelector(getCampaignPreview(campaignId));
  const name = get(campaign, 'name');
  const fundsTotal = get(campaign, 'fundsTotal');
  const percent = get(campaign, 'percent');
  const daysRemaining = get(campaign, 'daysRemaining');

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">
          {`$${fundsTotal}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Link href={`/campaign/${campaignId}`} css={textStyle.link}>
          <Typography variant="subtitle2" css={textStyle.overflow}>
            {name}
          </Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">
          {`${percent}%`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">
          {`${daysRemaining}`}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

CampaignPreviewTableRow.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default CampaignPreviewTableRow;

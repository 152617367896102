import React from 'react';
import * as firebase from "firebase/app";
import "firebase/auth";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';

const firebaseUI = css`
  margin-top: 50px;
`;

const FirebaseUIPage = ({
  shouldRedirect,
}) => {
  let uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    ],
  };

  if (!shouldRedirect) {
    uiConfig = {
      ...uiConfig,
      callbacks: {
        signInSuccessWithAuthResult: () => false,
      },
    }
  }

  return (
    <div css={firebaseUI}>
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      />
    </div>
  );
}

FirebaseUIPage.defaultProps = {
  shouldRedirect: true,
}

export default FirebaseUIPage;

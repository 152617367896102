import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

const SET_IS_LOADING = 'MY_CAMPAIGNS/SET_IS_LOADING';
const SET_MY_CAMPAIGNS = 'MY_CAMPAIGNS/SET_MY_CAMPAIGNS';

const initialState = {
  isLoadingPages: {}, // { 0: true, 1: false }
  myCampaignsLists: {}, // { 0: [campaignId, campaignId ] }
  myCampaignsData: {},
};

// TODO: Test MyCampaignsPage with more than one campaign (with funds)
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoadingPages: {
          ...state.isLoadingPages,
          [payload.page]: payload.isLoading,
        },
      }
    case SET_MY_CAMPAIGNS:
      return {
        ...state,
        myCampaignsLists: {
          ...state.myCampaignsLists,
          [payload.page]: payload.myCampaignsList,
        },
        myCampaignsData: {
          ...state.myCampaignsData,
          ...payload.myCampaignsData,
        },
      }
    default:
      return state;
  }
}

export const setIsLoadingMyCampaigns = (page, isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: {
      page,
      isLoading,
    },
  }
}

export const setMyCampaigns = (page, myCampaigns) => {
  const myCampaignsList = map(myCampaigns, campaign => campaign.id);
  const myCampaignsData = reduce(myCampaigns, (result, campaign) => ({ ...result, [campaign.id]: campaign }), {})
  return {
    type: SET_MY_CAMPAIGNS,
    payload: {
      page,
      myCampaignsList,
      myCampaignsData,
    }
  }
}

export const getIsLoadingMyCampaigns = (page) => (state) => {
  return state.myCampaigns.isLoadingPages[page];
}

export const getMyCampaignsList = (page) => (state) => {
  return state.myCampaigns.myCampaignsLists[page];
}

export const getMyCampaign = (campaignId) => (state) => state.myCampaigns.myCampaignsData[campaignId];

export const getMyCampaignHasChargedBackers = (campaignId) => (state) => {
  const chargedFunds = filter(
    state.myCampaigns.campaignFundsData,
    (fund) => {
      return fund.campaignId === campaignId && fund.hasBeenCharged
    }
  );
  return chargedFunds.length > 0;
}

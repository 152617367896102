import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getStripeAccountId,
} from '../redux/auth';
import {
  createPaymentIntentForStake,
} from '../api/campaigns';
import get from 'lodash/get';
import StripeWidget, { STRIPE_WIDGET_TYPES } from './StripeWidget';
import PaymentTable from './PaymentTable';
import { DACKIE_HELP_EMAIL } from '../utils/contact';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core';
import formStyles from '../styles/form';

const card = css`
  padding: 16px;
  max-width: 400px;
`;

const calculateTotalFromAmount = (amount) => {
  const amountInCents = amount * 100;
  const total = Math.ceil((amountInCents + 30) / 0.971);
  return total / 100;
}

const StakePayment = ({
  stake,
  stakeCurrency,
  campaignId,
}) => {
  const stripeAccountId = useSelector(getStripeAccountId);

  const total = calculateTotalFromAmount(stake);

  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);

  const onClickCreatePaymentIntent = async () => {
    if (!campaignId) {
      return;
    }
    setIsLoading(true);
    const data = await createPaymentIntentForStake(campaignId);
    const secret = get(data, 'clientSecret');
    if (secret) {
      setClientSecret(secret);
    }
    setIsLoading(false);
  }

  const onPaymentSuccess = () => setIsPaymentSuccess(true);

  const onPaymentFail = () => setIsPaymentError(true);

  const onPaymentError = () => setIsPaymentError(true);

  return (
    <>
      {
        // TODO: Don't pay stake twice
        // TODO: Can't pay when no campaignId
      }
      {!stripeAccountId ? (
        <Button
          variant="outlined"
          href='/profile'
        >
          Sign up with Stripe!
        </Button>
      ) : null}
      {stripeAccountId && !clientSecret && stake ? (
        <Card css={card}>
          <PaymentTable
            amount={stake}
          />
          <Button
            variant="outlined"
            disabled={!stake || isLoading}
            onClick={onClickCreatePaymentIntent}
          >
            {`Pay $${total.toFixed(2)} ${stakeCurrency} with Stripe!`}
          </Button>
        </Card>
      ) : null}
      {clientSecret && !isPaymentSuccess && !isPaymentError ? (
        <div css={formStyles.field}>
          <StripeWidget
            type={STRIPE_WIDGET_TYPES.CARD_PAYMENT}
            amountWithoutFees={stake}
            amountWithFees={total}
            clientSecret={clientSecret}
            onSuccess={onPaymentSuccess}
            onFail={onPaymentFail}
            onError={onPaymentError}
          />
        </div>
      ) : null}
      {isPaymentSuccess ? (
        <div>
          <div>
            <Typography>Your stake payment was successful!</Typography>
          </div>
          <div>
            <Typography>Your campaign is now live here:</Typography>
            <Button
              variant="outlined"
              href={`/campaign/${campaignId}`}
            >
              To my campaign!
            </Button>
          </div>
        </div>
      ) : null}
      {isPaymentError ? (
        <div>
          <div>
            <Typography>Your stake payment was not successful.</Typography>
          </div>
          <div>
            <Typography>
              {`Please contact ${DACKIE_HELP_EMAIL} for help.`}
            </Typography>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default StakePayment;

import get from 'lodash/get';

const SET_HAS_LOADED = 'CAMPAIGN_RATING/SET_HAS_LOADED';
const SET_IS_LOADING = 'CAMPAIGN_RATING/SET_IS_LOADING';
const SET_CAMPAIGN_RATING = 'CAMPAIGN_RATING/SET_CAMPAIGN_RATING';
const SET_CAMPAIGN_RATING_TEMPLATE = 'CAMPAIGN_RATING/SET_CAMPAIGN_RATING_TEMPLATE';
const SET_CAMPAIGN_RATING_FORM_DATA = 'CAMPAIGN_RATING/SET_CAMPAIGN_RATING_FORM_DATA';

const initialState = {
  hasLoaded: false,
  isLoading: false,
  data: {},
  template: {},
  form: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_HAS_LOADED:
      return {
        ...state,
        hasLoaded: payload,
      }
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_CAMPAIGN_RATING:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.campaignId]: payload[payload.campaignId],
        },
      }
    case SET_CAMPAIGN_RATING_TEMPLATE:
      return {
        ...state,
        template: payload,
      }
    case SET_CAMPAIGN_RATING_FORM_DATA:
      const existingFormData = get(state, `form[${payload.campaignId}][${payload.key}]`, {});
      return {
        ...state,
        form: {
          ...state.form,
          [payload.campaignId]: {
            ...state.form[payload.campaignId],
            [payload.key]: {
              ...existingFormData,
              [payload.fieldKey]: payload.value,
            }
          }
        }
      }
    default:
      return state;
  }
}

export function setHasLoaded(hasLoaded) {
  return {
    type: SET_HAS_LOADED,
    payload: hasLoaded,
  }
}

export function setIsLoading(isLoading) {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}

export function setCampaignRating(campaignId, campaignRating) {
  return {
    type: SET_CAMPAIGN_RATING,
    payload: { [campaignId]: campaignRating, campaignId },
  }
}

export function setCampaignRatingTemplate(template) {
  return {
    type: SET_CAMPAIGN_RATING_TEMPLATE,
    payload: template,
  };
}

export function setCampaignRatingFormValue(campaignId, key, value) {
  return {
    type: SET_CAMPAIGN_RATING_FORM_DATA,
    payload: { campaignId, key, value, fieldKey: 'value' },
  }
}

export function setCampaignRatingFormError(campaignId, key, errorMessage) {
  return {
    type: SET_CAMPAIGN_RATING_FORM_DATA,
    payload: { campaignId, key, value: errorMessage, fieldKey: 'error' },
  }
}

export function getHasLoaded(state) {
  return state.campaignRatings.hasLoaded;
}

export function getIsLoading(state) {
  return state.campaignRatings.isLoading;
}

export function getCampaignRating(campaignId) {
  return (state) => state.campaignRatings.data[campaignId];
}

export function getCampaignRatingTemplate(state) {
  return state.campaignRatings.template;
}

export function getCampaignRatingTemplateField(key) {
  return (state) => get(state, `campaignRatings.template[${key}]`);
}

export function getCampaignRatingForm(campaignId) {
  return (state) => get(state, `campaignRatings.form[${campaignId}]`);
}

export function getCampaignRatingFormField(campaignId, key) {
  return (state) => get(state, `campaignRatings.form[${campaignId}][${key}]`);
}

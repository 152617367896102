import React from 'react';
import PropTypes from 'prop-types';
import { BREAKPOINTS } from '../utils/layout';
import Footer from './Footer';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';

const content = css`
  flex: 1 0 auto;
`;

const standardLayout = css`
  padding: 8px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    padding: 16px;
  }
  @media (min-width: ${BREAKPOINTS.MD}px) {
    max-width: ${BREAKPOINTS.MD}px;
    margin: auto;
  }
`;

const StandardLayout = ({
  children,
}) => {
  return (
    <>
      <div css={content}>
        <div css={standardLayout}>
          {children}
        </div>
      </div>
      <Footer />
    </>
  );
};

StandardLayout.propTypes = {
  children: PropTypes.any,
};

export default StandardLayout;

import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import StripeWidget, { STRIPE_WIDGET_TYPES } from '../components/StripeWidget';
import PaymentTable, { calculateTotalFromAmount } from '../components/PaymentTable';
import {
  createSetupIntentForFunds,
} from '../api/campaigns';
import {
  getUserId,
} from '../redux/auth';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import lightGreen from '@material-ui/core/colors/lightGreen';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import formStyles from '../styles/form';

const container = css`
  background: ${lightGreen['50']};
  padding: 16px;
  border-radius: 4px;
`;

const title = css`
  margin-bottom: 8px;
`;

const fundInput = css`
  display: flex;
  align-items: baseline;
`;

const paymentTable = css`
  padding: 16px;
  max-width: 400px;
`;

const FundCampaignForm = ({
  suggestedFunds,
  campaignId,
}) => {
  const [funds, setFunds] = useState(suggestedFunds || 0);
  const [fundsCurrency, setFundsCurrency] = useState('usd');
  const [fundsError, setFundsError] = useState(null);
  const [fundsCurrencyError, setFundsCurrencyError] = useState(null);

  const [clientSecret, setClientSecret] = useState(null);
  const [isSetupIntentLoading, setIsSetupIntentLoading] = useState(false);

  const [redirect, setRedirect] = useState(null);

  const userId = useSelector(getUserId);

  const total = calculateTotalFromAmount(funds);

  const onFundsChange = (e) => {
    setFundsError(null);
    setFunds(parseFloat(e.target.value));
  }

  const onFundsCurrencyChange = (e) => {
    setFundsCurrencyError(null);
    setFundsCurrency(e.target.value);
  }

  const validate = () => {
    let isValid = true;
    if (!funds || (funds < 0.50 && fundsCurrency === 'usd')) {
      setFundsError('Minimum payment is $0.50');
      isValid = false;
    }
    if (!fundsCurrency) {
      setFundsCurrencyError('Currency is required')
      isValid = false;
    }
    return isValid;
  }

  const onClickBack = () => {
    setClientSecret(null);
  }

  const onClickNext = async () => {
    setIsSetupIntentLoading(true);
    if (!userId) {
      setRedirect('/login');
    } else {
      if (validate()) {
        // TODO: Doesn't save funds unless the webhook completes,
        // so this goes to a page without your data on it yet
        const data = await createSetupIntentForFunds(campaignId, funds, fundsCurrency);
        // Considered sending funds and fundsWithFees so the backend is guaranteed to have the same fundsWithFees
        // but that would let the user set their own fees
        setClientSecret(get(data, 'clientSecret'));
      }
      setIsSetupIntentLoading(false);
    }
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  return (
    <div css={container}>
      <Typography variant="subtitle1" css={title}>
        Support
      </Typography>
      {!clientSecret ? (
        <div css={fundInput}>
          <TextField
            variant="outlined"
            label="Funds"
            helperText={fundsError}
            name="funds"
            value={funds || ''} // State value initially undefined so need default value for controlled input
            type="number"
            onChange={onFundsChange}
            error={!!fundsError}
            disabled={isSetupIntentLoading}
          />
          <Select
            native
            variant="outlined"
            value={fundsCurrency}
            onChange={onFundsCurrencyChange}
            css={formStyles.inline}
            disabled={isSetupIntentLoading}
          >
            <option value={'usd'}>USD</option>
          </Select>
          <Button
            variant="outlined"
            onClick={onClickNext}
            css={[formStyles.inline, formStyles.button]}
            disabled={isSetupIntentLoading}
          >
            Next
          </Button>
        </div>
      ) : (
        <>
          <div css={paymentTable}>
            <PaymentTable
              amount={funds}
            />
          </div>
          <StripeWidget
            type={STRIPE_WIDGET_TYPES.CARD_SETUP}
            amountWithoutFees={funds}
            amountWithFees={total}
            clientSecret={clientSecret}
            onSuccess={() => setRedirect('/campaigns/funded')}
            onClickBack={onClickBack}
          />
          <Typography>
            {`$${total.toFixed(2)} will be charged only if this campaign meets its funding goal.`}
          </Typography>
        </>
      )}
    </div>
  );
}

export default FundCampaignForm;

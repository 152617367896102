import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  getStripeBalance,
  setStripeBalance,
} from '../redux/stripe';
import { getMyProfile, payOutBalance } from '../api/users';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const StripeBalanceWidget = () => {
  const dispatch = useDispatch();

  const balance = useSelector(getStripeBalance);

  const [shouldReload, setShouldReload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchStripeData = async () => {
      // TODO: Make endpoint only for Stripe balance
      const profileFromDB = await getMyProfile();
      const newBalance = get(profileFromDB, 'stripeAccount.balance')
      dispatch(setStripeBalance(newBalance));
      setIsLoading(false);
    }
    if (shouldReload !== null) {
      fetchStripeData();
    }
  }, [shouldReload])

  const onClickPayoutBalance = async () => {
    setIsLoading(true);
    await payOutBalance();
    setShouldReload(!shouldReload);
  }

  return (
    <>
      <Typography>{`Balance: $${balance ? balance.toFixed(2) : 0}`}</Typography>
      {balance > 0 ? (
        <Button variant="outlined" onClick={onClickPayoutBalance} disabled={isLoading}>
          Transfer Stripe balance to default payout method
        </Button>
      ) : null}
    </>
  );
}

export default StripeBalanceWidget;


const MS_PER_DAY = 24 * 60 * 60 * 1000;
const MS_PER_HOUR = 60 * 60 * 1000;
const MS_PER_MINUTE = 60 * 1000;
const MS_PER_SECOND = 1000;

export function getIsActive(startDate, duration) {
  if (!startDate || !duration) {
    return false;
  }
  const now = Date.now();
  const durationMs = parseInt(duration) * MS_PER_DAY;
  return parseInt(startDate) + durationMs > now;
}

export function getTimeRemaining(startDate, duration) {
  if (!startDate || !duration) {
    return null;
  }
  const now = Date.now();
  const startDateInt = parseInt(startDate);
  const durationMs = parseInt(duration) * MS_PER_DAY;
  if (startDateInt + durationMs < now) {
    return null;
  }

  const timeRemainingMs = startDate + durationMs - now;

  const partialDayMs = timeRemainingMs % MS_PER_DAY
  const fullDaysMs = timeRemainingMs - partialDayMs;
  const fullDays = fullDaysMs / MS_PER_DAY;

  const partialHourMs = partialDayMs % MS_PER_HOUR;
  const fullHoursMs = partialDayMs - partialHourMs;
  const fullHours = fullHoursMs / MS_PER_HOUR;

  const partialMinuteMs = partialHourMs % MS_PER_MINUTE;
  const fullMinutesMs = partialHourMs - partialMinuteMs;
  const fullMinutes = fullMinutesMs / MS_PER_MINUTE;

  const partialSecondMs = partialMinuteMs % MS_PER_SECOND;
  const fullSecondsMs = partialMinuteMs - partialSecondMs;
  const fullSeconds = fullSecondsMs / MS_PER_SECOND;

  return {
    days: fullDays,
    hours: fullHours,
    minutes: fullMinutes,
    seconds: fullSeconds,
  }
}

export default {
  getIsActive,
  getTimeRemaining,
};

import React from 'react';
import get from 'lodash/get';

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const DraftCampaignInfo = ({
  campaign,
  cssProp,
}) => {
  const name = get(campaign, 'name');
  const description = get(campaign, 'description');
  const fundingGoal = get(campaign, 'fundingGoal');
  const stake = get(campaign, 'stake');
  const duration = get(campaign, 'duration');
  const tagline = get(campaign, 'tagline');
  const ratingPeriod = get(campaign, 'ratingPeriod');
  const status = get(campaign, 'status');

  return (
    <div css={cssProp}>
      <Typography>
        {`Name: ${name}`}
      </Typography>
      <Typography>
        {`Tagline: ${tagline}`}
      </Typography>
      {description ? (
        <>
          <Typography>
            {'Description:'}
          </Typography>
          {description.split('\n').filter(paragraph => !!paragraph).map((paragraph, i) => (
            <Typography key={i} css={pageStyles.indent}>{paragraph}</Typography>
          ))}
        </>
      ) : null}
      <Typography>
        {`Funding goal: $${fundingGoal}`}
      </Typography>
      <Typography>
        {`Stake: $${stake}`}
      </Typography>
      <Typography>
        {`Duration: ${duration} ${duration === 1 ? 'day' : 'days'}`}
      </Typography>
      <Typography>
        {`Rating period: ${ratingPeriod} ${ratingPeriod === 1 ? 'day' : 'days'}`}
      </Typography>
      <Typography>
        {`Status: ${status}`}
      </Typography>
    </div>
  );
}

export default DraftCampaignInfo;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomePageItemLayout from '../layout/HomePageItemLayout';
import { hideOnMobileXs, BREAKPOINTS } from '../utils/layout'
import CampaignPreviewShelfTitle from './CampaignPreviewShelfTitle';
import CampaignPreviewTableRow from './CampaignPreviewTableRow';
import EmptyCampaignPreview from './EmptyCampaignPreview';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const horizontalRule = css`
  margin: 0 16px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin: 0 32px;
  }
`;

const campaignLoadingContainer = css`
  text-align: center;
`;

const emptyCampaignContainer = css`
  margin-top: 16px;
`;

const CampaignPreviewTable = ({
  title,
  subtitle,
  seeMoreText,
  seeMoreHref,
  fetchCampaigns,
  isLoadingSelector,
  setIsLoadingDispatch,
  campaignListSelector,
  setCampaignDataDispatch,
  hasHorizontalRule,
  emptyMessage,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  let campaignList = useSelector(campaignListSelector);
  const [displayOffset, setDisplayOffset] = useState(0);

  useEffect(() => {
    async function loadCampaigns() {
      dispatch(setIsLoadingDispatch(true));
      const campaigns = await fetchCampaigns();
      dispatch(setCampaignDataDispatch(campaigns));
      dispatch(setIsLoadingDispatch(false));
    }
    loadCampaigns();
  }, []);

  return (
    <>
      {hasHorizontalRule ? (
        <hr css={horizontalRule} />
      ) : null}
      <HomePageItemLayout>
        {isLoading ? (
          <div css={[pageStyles.homePageItem, campaignLoadingContainer]}>
            <Typography>Loading campaigns...</Typography>
          </div>
        ) : (
            <div css={pageStyles.homePageItem}>
              {campaignList && campaignList.length > 0 ? (
                <>
                  <CampaignPreviewShelfTitle
                    title={title}
                    subtitle={subtitle}
                    seeMoreText={seeMoreText}
                    seeMoreHref={seeMoreHref}
                    campaignListLength={0}
                    displayOffset={displayOffset}
                    setDisplayOffset={setDisplayOffset}
                  />
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Amount pledged</TableCell>
                          <TableCell>Campaign</TableCell>
                          <TableCell>Completion</TableCell>
                          <TableCell>Days remaining</TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                        {campaignList.map(campaignId => (
                          <CampaignPreviewTableRow
                            key={campaignId}
                            campaignId={campaignId}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {campaignList.length < 3 ? (
                    <div css={[hideOnMobileXs, emptyCampaignContainer]}>
                      <EmptyCampaignPreview />
                    </div>
                  ) : null}
                </>
              ) : (
                  <EmptyCampaignPreview
                    message={emptyMessage}
                  />
                )}
            </div>
          )}
      </HomePageItemLayout>
    </>
  );
}

CampaignPreviewTable.defaultProps = {
  hasHorizontalRule: true,
  emptyMessage: 'No campaigns are active at this time. Click here to start your own!',
}

export default CampaignPreviewTable;

import React from 'react';
import {
  getNewCampaigns,
  getTopActiveCampaigns,
} from '../api/campaigns';
import {
  getIsLoadingNewCampaigns,
  getIsLoadingTopActiveCampaigns,
  getNewCampaignsList,
  getTopActiveCampaignsList,
  setIsLoadingNewCampaigns,
  setIsLoadingTopActiveCampaigns,
  setNewCampaigns,
  setTopActiveCampaigns,
} from '../redux/campaignPreviews';
import HomePageLayout from '../layout/HomePageLayout';
import FrontPageShelf from '../components/FrontPageShelf';
import CampaignPreviewShelf from '../components/CampaignPreviewShelf';
import CampaignPreviewTable from '../components/CampaignPreviewTable';
import withAchievementsAlert from '../hoc/withAchievementsAlert';

const HomePage = () => {
  return (
    <HomePageLayout>
      <FrontPageShelf
      />

      <CampaignPreviewShelf
        title={'New Campaigns'}
        subtitle={'Be the first supporter!'}
        seeMoreText={'See all'}
        seeMoreHref={'/campaigns/new'}
        fetchCampaigns={getNewCampaigns}
        isLoadingSelector={getIsLoadingNewCampaigns}
        setIsLoadingDispatch={setIsLoadingNewCampaigns}
        campaignListSelector={getNewCampaignsList}
        setCampaignDataDispatch={setNewCampaigns}
        hasHorizontalRule={false}
        emptyMessage={'There are currently no campaigns that have not been pledged funds. Click here to start your own!'}
      />

      <CampaignPreviewTable
        title={'Top Active Campaigns'}
        seeMoreText={'See all stats'}
        seeMoreHref={'/stats'}
        fetchCampaigns={getTopActiveCampaigns}
        isLoadingSelector={getIsLoadingTopActiveCampaigns}
        setIsLoadingDispatch={setIsLoadingTopActiveCampaigns}
        campaignListSelector={getTopActiveCampaignsList}
        setCampaignDataDispatch={setTopActiveCampaigns}
        emptyMessage={'There are no campaigns that have been pledged funds at this time'}
      />
    </HomePageLayout>
  );
}

export default withAchievementsAlert(HomePage);

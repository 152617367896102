import React, { useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import formStyles from '../styles/form';
import stripeStyles from '../styles/stripe';

const STRIPE_WIDGET_TYPES = {
  CARD_PAYMENT: 'CARD_PAYMENT',
  CARD_SETUP: 'CARD_SETUP',
};

const StripeWidget = ({
  type,
  amountWithoutFees,
  amountWithFees,
  clientSecret,
  onSuccess,
  onFail,
  onError,
  isDisabled,
  onClickBack,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const amountWithoutFeesInDollars = amountWithoutFees.toFixed(2);
  const amountWithFeesInDollars = amountWithFees.toFixed(2);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const stripeFunction = type === STRIPE_WIDGET_TYPES.CARD_PAYMENT ? stripe.confirmCardPayment : stripe.confirmCardSetup;
    const result = await stripeFunction(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });
    console.log('StripeWidget result:', result)

    const error = get(result, 'error');
    const stripeObj = type === STRIPE_WIDGET_TYPES.CARD_PAYMENT ? get(result, 'paymentIntent') : get(result, 'setupIntent');

    if (error) {
      console.log('error:', error)
      onError && onError();
      // TODO: Check if this works
      setErrorMessage(get(error, 'message'))
      setIsLoading(false);
    } else {
      if (stripeObj.status === 'succeeded') {
        onSuccess && onSuccess();
      } else {
        onFail && onFail();
      }
    }
  }

  const buttonMessage = type === STRIPE_WIDGET_TYPES.CARD_PAYMENT ?
    `Pay $${amountWithFeesInDollars} now` :
    `Pledge $${amountWithoutFeesInDollars}`;

  const options = {
    disabled: isDisabled || isLoading,
    style: {
      base: {
        fontSize: '16px',
      }
    }
  };

  return (
    <>
      <div>
        <div css={stripeStyles.cardElement}>
          <CardElement options={options} />
        </div>
        <div css={formStyles.flexbox}>
          {onClickBack ? (
            <Button
              variant="outlined"
              disabled={!clientSecret || !amountWithFees || isLoading}
              onClick={onClickBack}
            >
              Back
            </Button>
          ) : null}
          <Button
            variant="outlined"
            disabled={!clientSecret || !amountWithFees || isLoading}
            onClick={handleSubmit}
            css={formStyles.button}
          >
            {buttonMessage}
          </Button>
        </div>
      </div>
      {errorMessage ? (
        <Typography>{errorMessage}</Typography>
      ) : null}
    </>
  );
}

export default StripeWidget;
export { STRIPE_WIDGET_TYPES };

import React from 'react';
import StandardLayout from '../layout/StandardLayout';

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';

function StatsPage() {
  return (
    <StandardLayout>
      <Typography variant="h5">
        Coming soon!
      </Typography>
    </StandardLayout>
  );
}

export default StatsPage;

import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import FundCampaignForm from './FundCampaignForm';
import TimeRemaining from './TimeRemaining';
import { getCampaign } from '../redux/campaigns';
import { getTimeRemaining } from '../utils/campaignUtils';
import { BREAKPOINTS, row, getCol } from '../utils/layout';

import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import textStyles from '../styles/text';

const campaignStatusCard = css`
  padding: 16px;
`;

const timeRemaining = css`
  margin-bottom: 8px;
`;

const progressBarContainer = css`
  max-width: 300px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: 300px;
  }
`;

const progressBar = css`
  height: 16px;
  margin-bottom: 4px;
`;

const progressBarInfo = css`
  display: flex;
  justify-content: space-between;
`;

const CampaignStatusCard = ({
  campaignId,
  cssProp,
}) => {
  const campaign = useSelector(getCampaign(campaignId))
  const status = get(campaign, 'status');
  const fundingGoal = get(campaign, 'fundingGoal');
  const fundsTotal = get(campaign, 'fundsTotal');
  const startDate = get(campaign, 'startDate');
  const duration = get(campaign, 'duration');

  const suggestedFunds = fundingGoal ? Math.ceil(fundingGoal / 20) : null;

  const percentComplete = fundsTotal && fundingGoal ? parseInt(fundsTotal / fundingGoal * 100) : 0;
  const fundsRemaining = fundingGoal - fundsTotal;
  const isCampaignActive = status !== 'archived' && getTimeRemaining(startDate, duration);
  const userFunds = get(campaign, 'userFunds');
  const userAlreadyFundedCampaign = !isEmpty(userFunds);

  const renderFinishedCampaignMessage = () => {
    return fundsTotal >= fundingGoal ? (
      <>
        <Typography>This campaign has finished successfully!</Typography>
        {userAlreadyFundedCampaign ? (
          <Typography>
            Congratulations on funding a successful campaign!!
          </Typography>
        ) : null}
      </>
    ) : (
      <>
        <Typography>This campaign has finished!</Typography>
        <Typography>Unfortunately, it did not meet its funding goal</Typography>
        {userAlreadyFundedCampaign ? (
          <Typography>
            Collect your payout {(
              <Link variant="body1" href="/campaigns/funded" css={textStyles.link}>
                here
              </Link>
            )}.
          </Typography>
        ) : null}
      </>
    );
  }

  return (
    <Card css={[campaignStatusCard, cssProp]}>
      {isCampaignActive ? (
        <div css={row}>
          <div css={getCol({ sm: 12, md: 5 })}>
            <div css={timeRemaining}>
              <TimeRemaining
                campaignId={campaignId}
                startDate={startDate}
                duration={duration}
              />
            </div>
            <div css={progressBarContainer}>
              <LinearProgress
                variant="determinate"
                value={percentComplete}
                css={progressBar}
              />
              {fundsRemaining && fundsRemaining > 0 ? (
                <div css={progressBarInfo}>
                  <Typography variant="subtitle2">
                    {`${percentComplete}% funded`}
                  </Typography>
                  <Typography variant="subtitle2">
                    {`$${fundsRemaining} to go`}
                  </Typography>
                </div>
              ) : null}
            </div>
          </div>
          <div css={[getCol({ sm: 12, md: 7 })]}>
            <FundCampaignForm
              campaignId={campaignId}
              suggestedFunds={suggestedFunds}
            />
          </div>
        </div>
      ) : renderFinishedCampaignMessage()}
    </Card>
  );
}

export default CampaignStatusCard;

import fountainPenEmoji from '../images/emoji/FountainPen32x32.png';
import smilingFaceWithSunglassesEmoji from '../images/emoji/SmilingFaceWithSunglasses32x32.png';
import moneyWithWingsEmoji from '../images/emoji/MoneyWithWings32x32.png';
import topHatEmoji from '../images/emoji/TopHat32x32.png';
import babyAngelEmoji from '../images/emoji/BabyAngel32x32.png';
import spoutingWhaleEmoji from '../images/emoji/SpoutingWhale32x32.png';
import directHitEmoji from '../images/emoji/DirectHit32x32.png';
import bowAndArrowEmoji from '../images/emoji/BowAndArrow32x32.png';
import pirateFlagEmoji from '../images/emoji/PirateFlag32x32.png';
import hammerAndWrenchEmoji from '../images/emoji/HammerAndWrench32x32.png';
import personLiftingWeightsEmoji from '../images/emoji/PersonLiftingWeights32x32.png';
import flexedBicepsEmoji from '../images/emoji/FlexedBiceps32x32.png';
import mechanicalArmEmoji from '../images/emoji/MechanicalArm32x32.png';
import bottleWithPoppingCorkEmoji from '../images/emoji/BottleWithPoppingCork32x32.png';
import militaryHelmetEmoji from '../images/emoji/MilitaryHelmet32x32.png';
import militaryMedalEmoji from '../images/emoji/MilitaryMedal32x32.png';
// import peopleHuggingEmoji from '../images/emoji/PeopleHugging32x32.png';
// import partyingFaceEmoji from '../images/emoji/PartyingFace32x32.png';

export const ACHIEVEMENT_ICONS_BY_KEY = {
  hasFilledOutBio: fountainPenEmoji,
  hasUploadedProfilePicture: smilingFaceWithSunglassesEmoji,
  fundCampaign1: moneyWithWingsEmoji,
  fundCampaign2: topHatEmoji,
  fundCampaign3: babyAngelEmoji,
  fundEntireCampaign: spoutingWhaleEmoji,
  collectPayout1: directHitEmoji,
  collectPayout2: bowAndArrowEmoji,
  collectPayout3: pirateFlagEmoji,
  hasCreatedCampaign: hammerAndWrenchEmoji,
  createCampaignWithMinStake1: personLiftingWeightsEmoji,
  createCampaignWithMinStake2: flexedBicepsEmoji,
  createCampaignWithMinStake3: mechanicalArmEmoji,
  successfulCampaign1: bottleWithPoppingCorkEmoji,
  successfulCampaign2: militaryHelmetEmoji,
  successfulCampaign3: militaryMedalEmoji,
}

import React, { useMemo } from 'react';

import Card from '@material-ui/core/Card';
import lime from '@material-ui/core/colors/lime';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core';

const BASE_COLOR = lime;
const OPTION_WIDTH = 50;

const getOptionCard = (numberOfOptions) => css`
  display: flex;
  width: ${numberOfOptions * OPTION_WIDTH}px;
`;

const baseOption = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${OPTION_WIDTH}px;
  height: ${OPTION_WIDTH}px;
`;

const enabledOption = css`
  cursor: pointer;
  &:hover {
    background-color: ${BASE_COLOR[100]};
  }
`;

const disabledOption = css`
  opacity: 0.5;
  cursor: default;
`;

const selectedOption = css`
  background-color: ${BASE_COLOR[300]};
  &:hover {
    background-color: ${BASE_COLOR[300]};
  }
`;

const unselectedOption = css`
  background-color: ${BASE_COLOR[50]};
`;

const NumberSelector = ({
  value,
  onChange,
  error,
  disabled,
  minValue,
  maxValue,
  helperText,
  cssProps,
}) => {
  const numberOfOptions = maxValue - minValue + 1;
  const options = useMemo(() => {
    const optionsArray = [...Array(numberOfOptions).keys()].map(value => {
      return value + minValue;
    }, []);
    return optionsArray;
  }, [minValue, maxValue]);

  return (
    <Card css={[getOptionCard(numberOfOptions), cssProps]}>
      {options.map(option => {
        const isSelected = value === option;
        const cssClasses = [baseOption];
        cssClasses.push(disabled ? disabledOption : enabledOption);
        cssClasses.push(isSelected ? selectedOption : unselectedOption);
        return (
          <div
            key={option}
            css={cssClasses}
            onClick={!disabled ? () => onChange(option) : null}
          >
            {option}
          </div>
        );
      })}
    </Card>
  );
}

export default NumberSelector;

const SET_IS_LOADING = 'DRAFT/SET_IS_LOADING';
const SET_CAMPAIGN = 'DRAFT/SET_CAMPAIGN';

const initialState = {
  isLoading: false,
  campaign: null,
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_CAMPAIGN:
      return {
        ...state,
        campaign: payload,
      }
    default:
      return state;
  }
}

export function setIsLoading(isLoading) {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}

export function setCampaign(campaign) {
  return {
    type: SET_CAMPAIGN,
    payload: campaign,
  }
}

export function getIsLoading(state) {
  return state.draftCampaign.isLoading;
}

export function getDraftCampaign(state) {
  return state.draftCampaign.campaign;
}

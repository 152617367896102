import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import indigo from '@material-ui/core/colors/indigo';
import lime from '@material-ui/core/colors/lime';
import { BREAKPOINTS } from '../utils/layout';

// https://material-ui.com/customization/typography/#responsive-font-sizes
const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: lime,
  },
  typography: {
    h4: {
      [`@media (max-width:${BREAKPOINTS.SM}px)`]: {
        fontSize: '1.5rem',
      }
    },
    h5: {
      [`@media (max-width:${BREAKPOINTS.SM}px)`]: {
        fontSize: '1.25rem',
      }
    },
    h6: {
      [`@media (max-width:${BREAKPOINTS.SM}px)`]: {
        fontSize: '1rem',
      }
    },
  }
})

export default theme;

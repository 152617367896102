import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get'
import {
  getMyProfile,
  makeExternalAccountDefault,
  deleteExternalAccount,
} from '../api/users';
import {
  getIsLoadingStripe,
  setIsLoadingStripe,
  getStripeExternalAccount,
  setStripeExternalAccounts,
} from '../redux/stripe';
import { zeroPad } from '../utils/format';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';

const externalAccountStyle = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ExternalAccount = ({
  id
}) => {
  const dispatch = useDispatch();

  const [shouldReload, setShouldReload] = useState(null);

  const isLoading = useSelector(getIsLoadingStripe);
  const externalAccount = useSelector(getStripeExternalAccount(id));
  const last4 = get(externalAccount, 'last4');
  const exp_month = get(externalAccount, 'exp_month');
  const exp_year = get(externalAccount, 'exp_year');
  const address_zip = get(externalAccount, 'address_zip');
  const default_for_currency = get(externalAccount, 'default_for_currency');
  const cardString = `**** **** **** ${last4}`;

  useEffect(() => {
    const fetchStripeData = async () => {
      // TODO: Make endpoint only for Stripe external accounts
      const profileFromDB = await getMyProfile();
      const externalAccountsData = get(profileFromDB, 'stripeAccount.externalAccounts.data', []);
      dispatch(setStripeExternalAccounts(externalAccountsData));
      dispatch(setIsLoadingStripe(false));
    }
    if (shouldReload !== null) {
      fetchStripeData();
    }
  }, [shouldReload]);

  const onClickMakeDefault = (id) => async () => {
    dispatch(setIsLoadingStripe(true));
    const res = await makeExternalAccountDefault(id);
    if (res) {
      setShouldReload(!shouldReload);
    } else {
      dispatch(setIsLoadingStripe(false));
    }
  }

  const onClickDelete = (id) => async () => {
    dispatch(setIsLoadingStripe(true));
    const res = await deleteExternalAccount(id);
    if (res) {
      setShouldReload(!shouldReload);
    } else {
      dispatch(setIsLoadingStripe(false));
    }
  }

  return (
    <div key={id} css={externalAccountStyle}>
      <div>
        <Typography>
          {cardString}
        </Typography>
        <Typography>
          {`${zeroPad(exp_month)}/${exp_year} ${address_zip}`}
        </Typography>
      </div>
      <div>
        {default_for_currency ? (
          <Typography>Default</Typography>
        ) : (
            <Button variant="outlined" onClick={onClickMakeDefault(id)} disabled={isLoading}>
              Make default
            </Button>
          )}
      </div>
      <div>
        {!default_for_currency ? (
          <div>
            <Button variant="outlined" onClick={onClickDelete(id)} disabled={isLoading}>
              Delete
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ExternalAccount;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get'
import { getMyProfile, updateUsername } from '../api/users';
import withAlert from '../hoc/withAlert';
import {
  setAlert,
} from '../redux/alerts';
import {
  getUsername,
  setUsername,
  getIsLoadingProfile,
  getIsLoadingUsername,
  setIsLoadingUsername,
} from '../redux/auth';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import formStyles from '../styles/form';
import pageStyles from '../styles/page';

const UsernameWidget = ({
  cssProp,
}) => {
  const dispatch = useDispatch();
  const usernameFromDB = useSelector(getUsername);
  const isLoadingProfile = useSelector(getIsLoadingProfile);
  const isLoadingUsername = useSelector(getIsLoadingUsername);
  const [isEditing, setIsEditing] = useState(false);
  const [newUsername, setNewUsername] = useState(usernameFromDB || '');
  const [usernameError, setUsernameError] = useState(null);
  const [shouldReload, setShouldReload] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      const profileFromDB = await getMyProfile();
      const username = get(profileFromDB, 'username', null);
      dispatch(setUsername(username));
      dispatch(setIsLoadingUsername(false));
    }
    if (shouldReload !== null) {
      fetchProfileData();
    }
  }, [shouldReload])

  const onUsernameChange = (e) => {
    setUsernameError(null);
    setNewUsername(e.target.value);
  }

  const onClickEdit = () => {
    setIsEditing(!isEditing);
  }

  const onClickSave = async () => {
    dispatch(setIsLoadingUsername(true));
    const savedUsername = await updateUsername(newUsername);
    if (savedUsername) {
      dispatch(setAlert('Username saved successfully!'))
      setShouldReload(!shouldReload);
    } else {
      dispatch(setIsLoadingUsername(false));
    }
    setNewUsername('');
    setIsEditing(false);
  }

  return !isLoadingProfile ? (
    <div css={cssProp}>
      {usernameFromDB ? (
        <div css={formStyles.fieldWithButton}>
          <Typography>
            {`Username: ${usernameFromDB}`}
          </Typography>
          <Button
            variant="outlined"
            onClick={onClickEdit}
            disabled={isLoadingUsername}
            css={formStyles.inline}
          >
            {!isEditing ? 'Edit' : 'Cancel'}
          </Button>
        </div>
      ) : (
        <>
          <Typography css={pageStyles.title}>
            Set your username so people know who you are!
          </Typography>
        </>
      )}
      {!usernameFromDB || isEditing ? (
        <div css={[formStyles.fieldWithButton, formStyles.popoutField]}>
          <TextField
            variant="outlined"
            label="Username"
            name="newUsername"
            value={newUsername}
            onChange={onUsernameChange}
            error={!!usernameError}
            helperText={usernameError || null}
            disabled={isLoadingUsername}
            inputProps={{ maxLength: 20 }}
          />
          <Button
            variant="outlined"
            onClick={onClickSave}
            disabled={isLoadingUsername || !newUsername}
            css={formStyles.inline}
          >
            Save
          </Button>
        </div>
      ) : null}
    </div>
  ) : (
      <div>
        <Typography>Loading...</Typography>
      </div>
  )
}

export default withAlert(UsernameWidget);

const SET_IS_LOADING = 'CAMPAIGN_PREVIEWS/SET_IS_LOADING';
const SET_CAMPAIGN_PREVIEWS = 'CAMPAIGN_PREVIEWS/SET_CAMPAIGN_PREVIEWS';

const initialState = {
  newCampaigns: {
    list: [],
    isLoading: true,
  },
  topActiveCampaigns: {
    list: [],
    isLoading: true,
  },
  data: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          isLoading: payload.isLoading,
        }
      }
    case SET_CAMPAIGN_PREVIEWS:
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          list: payload.list,
        },
        data: {
          ...state.data,
          ...payload.data,
        }
      }
    default:
      return state;
  }
}

function setIsLoading(key, isLoading) {
  return {
    type: SET_IS_LOADING,
    payload: {
      key,
      isLoading,
    }
  }
}

export function setIsLoadingNewCampaigns(isLoading) {
  return setIsLoading('newCampaigns', isLoading);
}

export function setIsLoadingTopActiveCampaigns(isLoading) {
  return setIsLoading('topActiveCampaigns', isLoading);
}

function setCampaignPreviews(key, campaignPreviews) {
  const campaignsList = campaignPreviews.map(campaign => campaign.id);
  const campaignsData = campaignPreviews.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {})
  return {
    type: SET_CAMPAIGN_PREVIEWS,
    payload: {
      key,
      list: campaignsList,
      data: campaignsData,
    },
  }
}

export function setNewCampaigns(newCampaigns) {
  return setCampaignPreviews('newCampaigns', newCampaigns);
}

export function setTopActiveCampaigns(topActiveCampaigns) {
  return setCampaignPreviews('topActiveCampaigns', topActiveCampaigns);
}

export function getIsLoadingNewCampaigns(state) {
  return state.campaignPreviews.newCampaigns.isLoading;
}

export function getIsLoadingTopActiveCampaigns(state) {
  return state.campaignPreviews.topActiveCampaigns.isLoading;
}

export function getNewCampaignsList(state) {
  return state.campaignPreviews.newCampaigns.list;
}

export function getTopActiveCampaignsList(state) {
  return state.campaignPreviews.topActiveCampaigns.list;
}

export function getCampaignPreview(id) {
  return (state) => state.campaignPreviews.data[id];
}


import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import {
  collectPayout,
  getUserFunds,
} from '../api/campaigns';
import { getMyProfile } from '../api/users';
import ItemsTable from '../components/ItemsTable';
import {
  getStripeExternalAccountsList,
  getStripeOnboardingLink,
  setStripeProfile,
} from '../redux/stripe';
import {
  getIsLoadingUserFunds,
  setIsLoadingUserFunds,
  getUserFundsList,
  setUserFunds,
  getUserFund,
} from '../redux/userFunds';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StandardLayout from '../layout/StandardLayout';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import formStyles from '../styles/form';

const FIELDS_TO_SHOW = [
  'Date',
  'Campaign',
  'Amount',
  'Status',
  'Completion',
  'Stake',
  'Payout',
];

const getActionButtons = (isDisabled) => {
  return [
    {
      fieldKey: '',
      text: 'Collect payout',
      shouldReloadPageOnClick: true,
      onClickHandler: async (fund) => {
        const fundId = get(fund, 'id');
        await collectPayout(fundId);
      },
      getIsDisplayed: (fund) => {
        const status = get(fund, 'status');
        const isArchived = status === 'archived';
        const fundsTotal = get(fund, 'fundsTotal');
        const fundingGoal = get(fund, 'fundingGoal');
        const hasBeenPaidOut = get(fund, 'hasBeenPaidOut');
        return isArchived && fundsTotal < fundingGoal && !hasBeenPaidOut;
      },
      getIsDisabled: () => isDisabled,
    }
  ]
};

const formatDate = (fund) => {
  const timestamp = get(fund, 'timestamp');
  return timestamp ? new Date(timestamp).toLocaleDateString() : null;
}

const formatName = (fund) => get(fund, 'name');

const formatAmount = (fund) => {
  const amount = get(fund, 'amount');
  return `$${amount}`;
}

const formatStatus = (fund) => {
  const status = get(fund, 'status');
  const fundsTotal = get(fund, 'fundsTotal');
  const fundingGoal = get(fund, 'fundingGoal');
  return status === "published" ? "In progress" : fundsTotal >= fundingGoal ? "Success" : "Failure";
}

const formatCompletion = (fund) => {
  const percentageComplete = get(fund, 'percentageComplete');
  return `${parseInt(percentageComplete * 100)}%`;
}

const formatStake = (fund) => {
  const stake = get(fund, 'stake');
  return `$${stake}`;
}

const formatPayout = (fund) => {
  const status = get(fund, 'status');
  const isArchived = status === 'archived';
  const fundsTotal = get(fund, 'fundsTotal');
  const fundingGoal = get(fund, 'fundingGoal');
  if (isArchived && fundsTotal < fundingGoal) {
    const hasBeenPaidOut = get(fund, 'hasBeenPaidOut');
    const payout = get(fund, 'payout');
    const payoutFormatted = payout.toFixed(2)
    const payoutMessage = hasBeenPaidOut ? `You collected $${payoutFormatted}!` : `Your payout is $${payoutFormatted}`;
    return payoutMessage;
  }
  return '';
}

const fieldLinks = {
  'Campaign': {
    getHref: (fund) => `/campaign/${get(fund, 'campaignId')}`
  }
}

const fieldProcessingFuncs = {
  'Date': formatDate,
  'Campaign': formatName,
  'Amount': formatAmount,
  'Status': formatStatus,
  'Completion': formatCompletion,
  'Stake': formatStake,
  'Payout': formatPayout,
}

const FundedCampaigns = () => {
  const dispatch = useDispatch();
  const onboardingLink = useSelector(getStripeOnboardingLink);
  const externalAccounts = useSelector(getStripeExternalAccountsList);
  const hasExternalAccounts = externalAccounts && externalAccounts.length > 0;

  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [redirect, setRedirect] = useState(null);

  const couldHaveOnboardingLink = isLoadingProfile || !!onboardingLink;
  const actionButtons = getActionButtons(couldHaveOnboardingLink);

  useEffect(() => {
    async function fetchProfile() {
      setIsLoadingProfile(true);
      const profileFromDB = await getMyProfile();
      const stripeProfile = {
        accountId: get(profileFromDB, 'stripeAccount.id', null),
        onboardingLink: get(profileFromDB, 'stripeAccount.onboardingLink', null),
        externalAccountsData: get(profileFromDB, 'stripeAccount.externalAccounts.data', []),
        customerId: get(profileFromDB, 'stripeCustomer.id', null),
      }
      dispatch(setStripeProfile(stripeProfile));
      setIsLoadingProfile(false);
    }
    fetchProfile();
  }, []);

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  return (
    <StandardLayout>
      <ItemsTable
        titleText="Campaigns I've Funded"
        subtitleText="Recently funded campaigns may take a few seconds to appear."
        fetchItemsFunc={getUserFunds}
        getItemIdsSelector={getUserFundsList}
        setItemsDispatch={setUserFunds}
        getItemByIdSelector={getUserFund}
        setIsLoadingDispatch={setIsLoadingUserFunds}
        getIsLoadingSelector={getIsLoadingUserFunds}
        fieldsToShow={FIELDS_TO_SHOW}
        fieldProcessingFuncs={fieldProcessingFuncs}
        fieldLinks={fieldLinks}
        actionButtons={actionButtons}
        pageSize={4}
      />
      {onboardingLink ? (
        <div css={formStyles.popoutField}>
          <Typography>
            Onboard with Stripe to collect payouts
          </Typography>
          <Button
            variant="outlined"
            onClick={() => window.location = onboardingLink}>
            Complete Stripe verification
          </Button>
        </div>
      ) : null}
      {!isLoadingProfile && !onboardingLink && !hasExternalAccounts ? (
        <div>
          <Typography>
            Add a payout method to your account to collect payouts
          </Typography>
          <Button variant="outlined" onClick={() => setRedirect('/profile')}>
            To Profile Page
          </Button>
        </div>
      ) : null}
    </StandardLayout>
  );
};

export default FundedCampaigns;

import React, { useEffect, useState } from 'react';
import * as firebase from "firebase/app";
import { useDispatch, useSelector } from 'react-redux';
import StandardLayout from '../layout/StandardLayout';
import get from 'lodash/get'
import withAuth from '../hoc/withAuth';
import { getMyProfile } from '../api/users';
import {
  getIsLoadingProfile,
  setIsLoadingProfile,
  setUsername,
  setBio,
} from '../redux/auth';
import {
  setHasAchieveds,
} from '../redux/achievements';
import { setStripeProfile } from '../redux/stripe';
import AchievementsGrid from '../components/AchievementsGrid';
import BioWidget from '../components/BioWidget';
import PrivacyIndicator from '../components/PrivacyIndicator';
import StripeProfile from '../components/StripeProfile';
import UsernameWidget from '../components/UsernameWidget';
import { BREAKPOINTS } from '../utils/layout';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const section = css`
  padding: 12px;
  margin-bottom: 16px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    padding: 16px;
    margin-bottom: 32px;
  }
`;

const subsectionTitleContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const subsectionTitle = css`
  font-weight: 600;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-weight: 500;
  }
`;

const subsection = css`
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const onLogOut = () => {
  firebase.auth().signOut();
};

const ProfilePage = () => {
  const dispatch = useDispatch();
  const isLoadingProfile = useSelector(getIsLoadingProfile);

  const [isLoadingAuthData, setIsLoadingAuthData] = useState(true);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      dispatch(setIsLoadingProfile(true))
      const profileFromDB = await getMyProfile();
      const username = get(profileFromDB, 'username', null);
      const bio = get(profileFromDB, 'bio', null);
      dispatch(setUsername(username));
      dispatch(setBio(bio));
      const achievementKeyList = get(profileFromDB, 'achievementKeyList');
      dispatch(setHasAchieveds(achievementKeyList));
      const stripeProfile = {
        accountId: get(profileFromDB, 'stripeAccount.id', null),
        onboardingLink: get(profileFromDB, 'stripeAccount.onboardingLink', null),
        externalAccountsData: get(profileFromDB, 'stripeAccount.externalAccounts.data', []),
        customerId: get(profileFromDB, 'stripeCustomer.id', null),
        balance: get(profileFromDB, 'stripeAccount.balance'),
      }
      dispatch(setStripeProfile(stripeProfile));
      dispatch(setIsLoadingProfile(false));
    }
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setAuthUser(user);
        fetchUser(user.uid)
      }
      setIsLoadingAuthData(false);
    });
  }, []);

  return (
    <StandardLayout>
      {authUser ? (
        <>
          <Paper css={section}>
            <Typography variant="h5" css={pageStyles.title}>
              Account settings
            </Typography>
            <div css={subsection}>
              <div css={subsectionTitleContainer}>
                <Typography variant="h6" css={subsectionTitle}>
                  Login info
                </Typography>
                <PrivacyIndicator
                  isPublic={false}
                  tooltipText={'Your real name and email are never displayed to anyone else'}
                />
              </div>
              <div>
                <Typography>{`Name: ${authUser.displayName}`}</Typography>
              </div>
              <div>
                <Typography>{`Email: ${authUser.email}`}</Typography>
              </div>
            </div>
            <div css={subsection}>
              <div css={subsectionTitleContainer}>
                <Typography variant="h6" css={subsectionTitle}>
                  Username
                </Typography>
                <PrivacyIndicator
                  isPublic={true}
                  tooltipText={'Your username is visible on campaigns you create, campaigns you fund, and your public profile page.'}
                />
              </div>
              <UsernameWidget />
            </div>
            <div css={subsection}>
              <div css={subsectionTitleContainer}>
                <Typography variant="h6" css={subsectionTitle}>
                  Bio
                </Typography>
                <PrivacyIndicator
                  isPublic={true}
                  tooltipText={'Your bio is visible on campaigns you create and your public profile page.'}
                />
              </div>
              <BioWidget />
            </div>
          </Paper>
          <Paper css={section}>
            <Typography variant="h5" css={pageStyles.title}>
              Stripe settings
            </Typography>
            <StripeProfile
              isLoading={isLoadingProfile}
            />
          </Paper>
          <Paper css={section}>
            <div css={subsection}>
              <Typography variant="h5" css={pageStyles.title}>
                Achievements
              </Typography>
            </div>
            <AchievementsGrid />
          </Paper>
          <Button
            onClick={onLogOut}
            variant="outlined"
          >
            {isLoadingAuthData ? 'Loading' : 'Log out'}
          </Button>
        </>
      ) : null}
    </StandardLayout>
  );
}

export default withAuth(ProfilePage);

import React, { useState, useEffect } from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  saveCampaign,
  submitCampaignForReview,
} from '../api/campaigns';
import withAlert from '../hoc/withAlert';
import withAuth from '../hoc/withAuth';
import withDraftCampaign from '../hoc/withDraftCampaign';
import { getIsLoading, getDraftCampaign } from '../redux/draftCampaign'
import StandardLayout from '../layout/StandardLayout';
import {
  setAlert,
} from '../redux/alerts'
import {
  UI_DEFAULTS,
  INPUT_LIMITS,
  MIN_DURATION,
  MAX_DURATION,
  MIN_FUNDING_GOAL,
  MAX_FUNDING_GOAL,
  MIN_STAKE,
  MAX_STAKE,
  MIN_RATING_PERIOD,
  MAX_RATING_PERIOD,
} from '../utils/campaignModel';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import formStyles from '../styles/form';

const CreateCampaignPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/campaigns/edit/:id')
  const id = get(match, 'params.id');

  const draftCampaign = useSelector(getDraftCampaign);
  const draftCampaignIsLoading = useSelector(getIsLoading);

  const [name, setName] = useState(UI_DEFAULTS.name);
  const [description, setDescription] = useState(UI_DEFAULTS.description);
  const [fundingGoal, setFundingGoal] = useState(UI_DEFAULTS.fundingGoal);
  const [fundingGoalCurrency, setFundingGoalCurrency] = useState(UI_DEFAULTS.fundingGoalCurrency);
  const [stake, setStake] = useState(UI_DEFAULTS.stake);
  const [stakeCurrency, setStakeCurrency] = useState(UI_DEFAULTS.stakeCurrency);
  const [duration, setDuration] = useState(UI_DEFAULTS.duration);
  const [tagline, setTagline] = useState(UI_DEFAULTS.tagline);
  const [ratingPeriod, setRatingPeriod] = useState(UI_DEFAULTS.ratingPeriod);

  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [fundingGoalError, setFundingGoalError] = useState(null);
  const [stakeError, setStakeError] = useState(null);
  const [durationError, setDurationError] = useState(null);
  const [taglineError, setTaglineError] = useState(null);
  const [ratingPeriodError, setRatingPeriodError] = useState(null);

  const [isLoading, setIsLoading] = useState(draftCampaignIsLoading);
  const [createToEditRedirect, setCreateToEditRedirect] = useState(null);
  const [redirect, setRedirect] = useState(null);

  // Populate fields after campaign loads
  useEffect(() => {
    if (!draftCampaignIsLoading && draftCampaign) {
      setName(draftCampaign.name || UI_DEFAULTS.name);
      setDescription(draftCampaign.description || UI_DEFAULTS.description);
      setFundingGoal(draftCampaign.fundingGoal || UI_DEFAULTS.fundingGoal);
      setFundingGoalCurrency(draftCampaign.fundingGoalCurrency || UI_DEFAULTS.fundingGoalCurrency);
      setStake(draftCampaign.stake || UI_DEFAULTS.stake);
      setStakeCurrency(draftCampaign.stakeCurrency || UI_DEFAULTS.stakeCurrency);
      setDuration(draftCampaign.duration || UI_DEFAULTS.duration);
      setTagline(draftCampaign.tagline || UI_DEFAULTS.tagline);
      setRatingPeriod(draftCampaign.ratingPeriod || UI_DEFAULTS.ratingPeriod);
    }
  }, [draftCampaignIsLoading]);

  if (createToEditRedirect && !id) {
    return (
      <Redirect to={createToEditRedirect} />
    )
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }

  // TODO Make sure you can't edit a published campaign
  // TODO Make sure you can't edit a campaign that's not yours

  const validateSave = () => {
    let isValid = true;
    if (!name) {
      setNameError('Name is required to save');
      isValid = false;
    }
    return isValid;
  }

  const validateSubmit = () => {
    let isValid = true;
    if (!name) {
      setNameError('Name is required');
      isValid = false;
    }
    if (!description) {
      setDescriptionError('Description is required');
      isValid = false;
    }
    if (!fundingGoal) {
      setFundingGoalError('Funding goal is required');
      isValid = false;
    } else if (fundingGoal > 10000) {
      setFundingGoalError('Funding goal cannot be more than $10,000 USD');
      isValid = false;
    }
    if (!stake) {
      setStakeError('Stake are required');
      isValid = false;
    } else if (parseFloat(stake) > parseFloat(fundingGoal)) {
      setStakeError('Stake must be less than funding goal')
      isValid = false;
    }
    if (!duration) {
      setDurationError('Duration is required');
      isValid = false;
    } else if (duration < 1 || duration > 60) {
      setDurationError('Duration must a number between 1 and 60')
      isValid = false;
    }
    if (!tagline) {
      setTaglineError('Tagline is required');
      isValid = false;
    }
    if (!ratingPeriod) {
      setRatingPeriodError('Rating period is required');
      isValid = false;
    } else if (ratingPeriod < MIN_RATING_PERIOD || ratingPeriod > MAX_RATING_PERIOD) {
      setRatingPeriodError(`Rating period must be between ${MIN_RATING_PERIOD} and ${MAX_RATING_PERIOD} days`);
      isValid = false;
    }
    return isValid;
  };

  const onClickSave = async () => {
    setIsLoading(true);
    if (validateSave()) {
      const campaign = {
        id, name, description,
        fundingGoal, fundingGoalCurrency,
        stake, stakeCurrency,
        duration, tagline, ratingPeriod,
      }
      const savedCampaign = await saveCampaign(campaign);
      const savedCampaignId = get(savedCampaign, 'id')
      if (savedCampaignId) {
        if (!id) {
          // If campaign was just created, redirect to edit page
          setCreateToEditRedirect(`/campaigns/edit/${savedCampaignId}`);
        } else {
          // If campaign was not just created, stay on edit page, show Snackbar
          dispatch(setAlert('Campaign saved successfully!'));
        }
      }
    } else {
      window.scrollTo(0, 0);
    }
    setIsLoading(false);
  };

  const onClickSubmit = async () => {
    // TODO: Inputs don't turn off when submitting
    setIsLoading(true)
    
    if (validateSubmit()) {
      const campaign = {
        id, name, description,
        fundingGoal, fundingGoalCurrency,
        stake, stakeCurrency,
        duration, tagline, ratingPeriod,
      }
      const savedCampaign = await submitCampaignForReview(campaign)
      const savedCampaignId = get(savedCampaign, 'id')
      if (savedCampaignId) {
        setRedirect(`/campaigns/review/${id}`)
      }
    } else {
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  };

  const onNameChange = (e) => {
    setNameError(null);
    setName(e.target.value);
  };

  const onDescriptionChange = (e) => {
    setDescriptionError(null);
    setDescription(e.target.value);
  };

  const onFundingGoalChange = (e) => {
    setFundingGoalError(null);
    setFundingGoal(e.target.value);
  }

  const onStakeChange = (e) => {
    setStakeError(null);
    setStake(e.target.value);
  };

  const onStakeCurrencyChange = (e) => {
    // setStakeCurrencyError(null); // No stake currency error for now
    setStakeCurrency(e.target.value)
  }

  const onTaglineChange = (e) => {
    setTaglineError(null);
    setTagline(e.target.value);
  }

  const changeBoundedValueHandler = (minValue, maxValue, setValue, setError) => {
    return (e) => {
      setError(null);
      const newValue = e.target.value;
      const isBlank = newValue === '';
      const isValidValue = isBlank || (newValue >= minValue && newValue <= maxValue);
      if (isValidValue) {
        setValue(isBlank ? newValue : parseInt(newValue));
      } else {
        setError(`Must be between ${minValue} and ${maxValue}`);
      }
    }
  }

  return (
    <StandardLayout>
      <div css={formStyles.field}>
        <Typography variant="h4">
          {id ? 'Edit campaign' : 'Create campaign'}
        </Typography>
      </div>
      <div css={formStyles.field}>
        <TextField
          variant="outlined"
          label="Campaign name"
          fullWidth
          name="name"
          value={name}
          onChange={onNameChange}
          error={!!nameError}
          helperText={nameError || null}
          disabled={isLoading}
          inputProps={{ maxLength: INPUT_LIMITS.name }}
        />
      </div>
      <div css={formStyles.field}>
        <TextField
          variant="outlined"
          label="Description"
          helperText={descriptionError || "What are you trying to achieve and why? What are you planning to do with the funds?"}
          fullWidth
          multiline
          rows={10}
          rowsMax={20}
          name="description"
          value={description}
          onChange={onDescriptionChange}
          error={!!descriptionError}
          disabled={isLoading}
          inputProps={{ maxLength: INPUT_LIMITS.description }}
        />
      </div>
      <div css={formStyles.field}>
        <TextField
          variant="outlined"
          label="Funding goal"
          helperText={fundingGoalError || "How much money are you trying to raise?"}
          name="fundingGoal"
          value={fundingGoal}
          type="number"
          onChange={changeBoundedValueHandler(MIN_FUNDING_GOAL, MAX_FUNDING_GOAL, setFundingGoal, setFundingGoalError)}
          error={!!fundingGoalError}
          disabled={isLoading}
          css={formStyles.partialWidth}
        />
        <Select
          native
          variant="outlined"
          value={fundingGoalCurrency}
          onChange={(e) => setFundingGoalCurrency(e.target.value)}
          disabled={isLoading}
          css={formStyles.inline}
        >
          <option value={'usd'}>USD</option>
        </Select>
      </div>
      <div css={formStyles.field}>
        <TextField
          variant="outlined"
          label="Stake"
          helperText={stakeError || "How much money are you putting up as a stake?"}
          name="stake"
          value={stake || ''} // State value initially undefined so need default value for controlled input
          type="number"
          onChange={changeBoundedValueHandler(MIN_STAKE, MAX_STAKE, setStake, setStakeError)}
          error={!!stakeError}
          disabled={isLoading}
          css={formStyles.partialWidth}
        />
        <Select
          native
          variant="outlined"
          value={stakeCurrency}
          onChange={onStakeCurrencyChange}
          disabled={isLoading}
          css={formStyles.inline}
        >
          <option value={'usd'}>USD</option>
        </Select>
      </div>
      <div css={formStyles.field}>
        <TextField
          variant="outlined"
          label="Duration (in days)"
          helperText={durationError || "How long is your campaign?"}
          name="duration"
          value={duration}
          type="number"
          onChange={changeBoundedValueHandler(MIN_DURATION, MAX_DURATION, setDuration, setDurationError)}
          error={!!durationError}
          css={formStyles.partialWidth}
          disabled={isLoading}
        />
      </div>
      <div css={formStyles.field}>
        <TextField
          variant="outlined"
          label="Tagline"
          helperText={taglineError || "Tagline appears in short descriptions of your campaign, e.g. on the home page"}
          name="tagline"
          value={tagline}
          onChange={onTaglineChange}
          error={!!taglineError}
          inputProps={{ maxLength: INPUT_LIMITS.tagline }}
          disabled={isLoading}
        />
      </div>
      <div css={formStyles.field}>
        <Typography>
          If your campaign succeeds, we ask funders to anonymously rate you on things like quality of work and likelihood of completing the project.
          Depending on the scope of your project, you can adjust how long we should wait to email funders.
          After receiving the email, funders will have 7 days to complete their rating.
        </Typography>
      </div>
      <div css={formStyles.field}>
        <TextField
          variant="outlined"
          label="Rating period"
          helperText={ratingPeriodError || 'How long should we wait to email users?'}
          name="ratingPeriod"
          value={ratingPeriod}
          type="number"
          onChange={changeBoundedValueHandler(MIN_RATING_PERIOD, MAX_RATING_PERIOD, setRatingPeriod, setRatingPeriodError)}
          error={!!ratingPeriodError}
          disabled={isLoading}
          inputProps={{ min: 1, max: 90 }}
          css={formStyles.partialWidth}
        />
      </div>
      <div>
        <Button
          onClick={onClickSave}
          disabled={isLoading}
          variant="outlined"
        >
          Save
        </Button>
        <Button
          onClick={onClickSubmit}
          disabled={isLoading || !id}
          variant="outlined"
          css={formStyles.inline}
        >
          Submit for review
        </Button>
      </div>
    </StandardLayout>
  );
}

export default withAuth(withDraftCampaign(withAlert(CreateCampaignPage)));

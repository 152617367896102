const SET_ALERT = 'ALERTS/SET_ALERT';

const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_ALERT:
      return {
        ...state,
        data: payload,
      }
    default:
      return state;
  }
}

export const setAlert = (message, image, duration) => {
  return {
    type: SET_ALERT,
    payload: {
      message,
      image,
      duration,
    },
  }
}

export const getAlert = (state) => {
  return state.alerts.data;
}

import React from "react";
import StandardLayout from "../layout/StandardLayout";
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const terms = [
  {
    header: "Who We Are",
    paragraphs: [
      `Dackie LLC, its platform Dackie.io, and its parents, subsidiaries, affiliates, agents, representatives,
            consultants, employees, officers, and directors (collectively, "Dackie," "we," or "us," "our"), provide a
            marketplace to allow users to raise funds for their own research projects, contribute to others’ projects,
            and contract researchers to work on their projects.`,
    ],
  },
  {
    header: "Application of These Terms of Service",
    paragraphs: [
      `THESE TERMS OF SERVICE CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU, THE USER OF DACKIE.IO (THE "SITE"), AND
            DACKIE, THE OWNER OF THE SITE. BY USING THIS SITE AND THE SERVICES PROVIDED ON THIS SITE (THE "SERVICES")
            YOU ARE AGREEING TO THESE LEGALLY BINDING RULES (THESE "TERMS"). YOU ARE ALSO AGREEING TO OUR PRIVACY
            POLICY. IF YOU DO NOT WISH TO CONTINUE TO BE BOUND BY THESE TERMS, THEN YOU MUST STOP USING THE WEBSITE
            IMMEDIATELY.`,
      `We may change our Terms of Service from time to time. If we do, we will notify you on our Site or by
            sending you an email detailing the changes and the date the new Terms of Service go into effect.`
    ],
  },
  {
    header: "Your Account",
    paragraphs: [
      `When you access the Site you are agreeing to these Terms of Service and our Privacy Policy, even if you do
            not create an account. If you create an account, you agree that you will provide complete and accurate
            information when registering. You will be responsible for all activity on your account. If someone uses
            your account without your permission, please report the unauthorized use to us immediately. To sign up for
            an account, you must be at least 18 years of age or old enough to form a binding contract.`,
      `When you create an account, we grant you a limited, non-exclusive, non-transferable, revocable license to
            use the Services subject to the limitations set forth herein, including the following limitations in
            particular: you will not use the Site directly or indirectly to break the law of any jurisdiction; present
            information that is false or misleading; threaten, abuse, or harass anyone; spam through unsolicited
            advertising or promotional material; distribute viruses or any software designed to interfere with proper
            computer function; or abuse the Personal Data (as defined in our Privacy Policy) of others obtained through
            your use of the Site.`,
    ],
  },
  {
    header: "Termination",
    paragraphs: [
      `We have the right to monitor, terminate, suspend, or delete any account at any time for any reason, or no
            reason at all, and we have no obligation to provide you with a reason for termination.`,
      `We will not have any liability to you or third parties for any use of the Services associated with your
            account. Upon termination, you must cease all use of the Services and Content (defined below). All
            representations and warranties shall survive termination.`,
    ],
  },
  {
    header: "Intellectual Property",
    paragraphs: [
      `The Site may include text, graphics, logos, icons, images, sound clips, video clips, and data compilations
            ("Content"). All of the Content on the Site is the property of Dackie and is protected by intellectual
            property and copyright laws. Except where provided under the doctrine of “fair use” or otherwise explicitly
            agreed to, you may not reproduce, copy, distribute, store, or re-use Content from the Site.`,
    ],
  },
  {
    header: "Links to Other Websites",
    paragraphs: [
      `Unless we expressly say otherwise, websites liked on the Site are not under the control of us or our
            affiliates. We assume no responsibility for the content of such websites and we disclaim any and all
            liability for any and all forms of loss or damage arising out of the use of such linked websites.`,
    ],
  },
  {
    header: "What We Are Not Responsible For",
    paragraphs: [
      `Dackie is not liable for any damages or losses related to your use of the Services. We are not liable for
            research funding or other peer-to-peer funding issues. We don’t become involved in disputes between users,
            or between users and any third party relating to the use of the Services. We do not oversee the performance
            or punctuality of Research Projects and we do not endorse any content users submit to the Site. When you
            use the Services, you release Dackie from claims, damages, and demands of every kind, known or unknown,
            suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes
            and the Services. All content you access through the Services is at your own risk. You are solely
            responsible for any resulting damage or loss to any party.`,
    ],
  },
  {
    header: "Disclaimers",
    paragraphs: [
      `If you are an account holder and you have agreed to our Membership Agreement, you may have recourse
            depending on the circumstances if something goes wrong with your use of the Website. Any recourse is
            limited by the following disclaimers:`,
    ],
    bullets: [
      `WE HAVE NO FIDUCIARY DUTY TO ANY USER.`,
      `WE MAKE NO WARRANTY OR REPRESENTATION, EXPRESS OR IMPLIED, THAT THE SITE WILL MEET YOUR REQUIREMENTS, THAT
            IT WILL BE OF SATISFACTORY QUALITY, THAT IT WILL BE FIT FOR A PARTICULAR PURPOSE, THAT IT WILL NOT INFRINGE
            THE RIGHTS OF THIRD PARTIES, THAT IT WILL BE COMPATIBLE WITH ALL SYSTEMS, THAT IT WILL BE SECURE, OR THAT
            ALL INFORMATION PROVIDED WILL BE ACCURATE.`,
      `WE MAKE NO GUARANTEE OF ANY SPECIFIC RESULTS FROM THE USE OF THE SITE.`,
      `NO PART OF THE SITE IS INTENDED TO CONSTITUTE ADVICE, AND THE CONTENT OF THE SITE SHOULD NOT BE RELIED
            UPON WHEN MAKING ANY DECISIONS OR TAKING ANY ACTION OF ANY KIND.`,
      `THE SITE IS PROVIDED "AS IS" AND ON AN "AS AVAILABLE" BASIS, AND WE GIVE NO WARRANTY THAT IT WILL BE FREE
            OF DEFECTS AND/OR FAULTS.`,
      `WE ACCEPT NO LIABILITY FOR ANY DISRUPTION OR NON-AVAILABILITY OF THE SITE RESULTING FROM EXTERNAL CAUSES
            INCLUDING, BUT NOT LIMITED TO, ISP EQUIPMENT FAILURE, HOST EQUIPMENT FAILURE, COMMUNICATIONS NETWORK
            FAILURE, POWER FAILURE, NATURAL EVENTS, ACTS OF WAR, OR LEGAL RESTRICTIONS AND CENSORSHIP.`,
      `TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE ACCEPT NO LIABILITY FOR ANY DIRECT OR INDIRECT LOSS OR DAMAGE,
            FORESEEABLE OR OTHERWISE, INCLUDING ANY INDIRECT, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES ARISING FROM
            YOUR USE OF THE SITE OR ANY INFORMATION CONTAINED IN IT, AND YOU USE THE SITE AND ITS CONTENT AT YOUR OWN
            RISK. THIS IS NOT INTENDED TO EXCLUDE OR RESTRICT OUR LIABILITY FOR DEATH OR PERSONAL INJURY ARISING FROM
            YOUR USE OF THE SITE.`,
      `YOU USE THE SITE AND ITS CONTENT AT YOUR OWN RISK.`,
    ],
  },
  {
    header: "Indemnity",
    paragraphs: [
      `YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS DACKIE, ITS SUBSIDIARIES AND AFFILIATED COMPANIES, AND
            THEIR OFFICERS, DIRECTORS, EMPLOYEES, PARTNERS, CONTRACTORS, REPRESENTATIVES, AGENTS, AND THIRD PARTY
            PROVIDERS FROM AND AGAINST ANY AND ALL CLAIMS, CAUSES OF ACTION, DAMAGES, OBLIGATIONS, LOSSES, LIABILITIES,
            COSTS OR DEBT, AND EXPENSES (INCLUDING REASONABLE ATTORNEYS' FEES AND COSTS) AND ALL AMOUNTS PAID IN
            SETTLEMENT ARISING FROM OR RELATING TO, BREACH OF THESE TERMS OR VIOLATION OF ANY LAWS. WE RESERVE THE
            RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER FOR WHICH YOU HAVE
            AGREED TO INDEMNIFY US, AND YOU AGREE TO ASSIST AND COOPERATE WITH US AS REASONABLY REQUIRED IN THE DEFENSE
            OR SETTLEMENT OF ANY SUCH MATTERS.`,
    ],
  },
  {
    header: "No Waiver",
    paragraphs: [
      `IN THE EVENT THAT EITHER YOU OR WE FAIL TO EXERCISE ANY RIGHT OR REMEDY CONTAINED IN THESE TERMS OF
            SERVICE, THAT DOES NOT MEAN YOU OR WE (AS APPLICABLE) HAVE WAIVED THAT RIGHT OR REMEDY AND SO SHALL NOT
            BE CONSTRUED AS A WAIVER.`,
    ]
  },
  {
    header: "Communications",
    paragraphs: [
      `If you wish to communicate with us about anything related to these Terms, you may do so by sending an
            email to support@dackie.io. If you have any other questions about the Site, you should email the appropriate address
            set out in the "Contact Us" section of the Site.`,
    ]
  },
  {
    header: "Governing Law",
    paragraphs: [
      `This agreement and all aspects of the Service shall be governed by and construed in accordance with the
            internal laws of the United States and the State of Delaware regardless of your location, except that the
            arbitration provision shall be governed by the Federal Arbitration Act. For the purpose of any judicial
            proceeding to enforce such award or incidental to such arbitration or to compel arbitration, or if for any
            reason a claim proceeds in court rather than in arbitration, the parties hereby submit to the non-exclusive
            jurisdiction of the state and Federal courts sitting in Chicago, Illinois. With respect to any disputes or
            claims not subject to informal dispute resolution or arbitration (as set forth below), you agree not to
            commence or prosecute any action in connection therewith other than in the state and federal courts located
            in Chicago, Illinois and you hereby consent to, and waive all defenses of lack of personal jurisdiction and
            forum non conveniens with respect to, venue and jurisdiction in the state and federal courts located in
            Chicago, Illinois.`,
    ],
  },
  {
    header: "Informal Negotiations",
    paragraphs: [
      `To expedite resolution and control the cost of any dispute, controversy or claim related to this agreement
            ("Dispute"), you and Dackie agree to first attempt to negotiate any Dispute (except those Disputes
            expressly provided below) informally for at least thirty (30) days before initiating any arbitration or
            court proceeding. Such informal negotiations commence upon written notice from one party to the other.`,
    ],
  },
  {
    header: "Arbitration",
    paragraphs: [
      `If you and Dackie are unable to resolve a Dispute through informal negotiations, either you or Dackie may
            elect to have the Dispute finally and exclusively resolved by binding arbitration. Any election to
            arbitrate by one party shall be final and binding on the other. YOU UNDERSTAND THAT ABSENT THIS PROVISION,
            YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced and
            conducted under the Commercial Arbitration Rules of the American Arbitration Association ("AAA") and,
            where appropriate, the AAA's Supplementary Procedures for Consumer Related Disputes ("AAA Consumer
            Rules"), both of which are available at the AAA website. The determination of whether a Dispute is subject
            to arbitration shall be governed by the Federal Arbitration Act and determined by a court rather than an
            arbitrator. Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA
            Rules and, where appropriate, limited by the AAA Consumer Rules. If such costs are determined by the
            arbitrator to be excessive, Dackie will pay all arbitration fees and expenses. The arbitration may be
            conducted in person, through the submission of documents, by phone or online. The arbitrator will make a
            decision in writing, but need not provide a statement of reasons unless requested by a party. The
            arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so.
            Except as otherwise provided in this agreement, you and Dackie may litigate in court to compel
            arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate or enter judgment on the
            award entered by the arbitrator. Judgment upon any award rendered by the arbitrator may be entered and
            enforcement obtained thereon in any court having jurisdiction. All arbitration proceedings shall be closed
            to the public and confidential, and all records relating thereto shall be permanently sealed, except as
            necessary to obtain court confirmation of the arbitration award. The arbitrator shall have authority to
            grant any form of appropriate relief, whether legal or equitable in nature, including specific performance.
            You and we agree to abide by all decisions and awards rendered in such proceedings. Such decisions and
            awards rendered by the arbitrator shall be final and conclusive. All such controversies, claims or disputes
            shall be settled in this manner in lieu of any action at law or equity.`,
    ],
  },
  {
    header: "Restrictions",
    paragraphs: [
      `You and Dackie agree that any arbitration shall be limited to the Dispute between Dackie and you
            individually. To the full extent permitted by law, (1) no arbitration shall be joined with any other; (2)
            there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class
            action procedures; and (3) there is no right or authority for any Dispute to be brought in a purported
            representative capacity on behalf of the general public or any other persons.`,
    ],
  },
];

const TermsOfService = () => {
  return (
    <StandardLayout>
      <div css={pageStyles.item}>
        <Typography variant="h4">
          Terms of Service
        </Typography>
      </div>
      {terms.map((section, i) => (
        <div key={i} css={pageStyles.item}>
          <div css={pageStyles.title}>
            <Typography variant="h5">
              {section.header}
            </Typography>
          </div>
          {section.paragraphs.map((paragraph, j) => (
            <Typography key={`${i}-${j}`}>
              {paragraph}
            </Typography>
          ))}
          {section.bullets ? (
            <ul>
              {section.bullets.map((bullet, k) => (
                <li key={`${i}.${k}`}>
                  <Typography>
                    {bullet}
                  </Typography>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      ))}
    </StandardLayout>
  );
};

export default TermsOfService;

import React from 'react';
import { Link as RRLink } from 'react-router-dom';

import {
  getNewCampaigns,
} from '../api/campaigns';
import {
  getIsLoadingNewCampaigns,
  getNewCampaignsList,
  setIsLoadingNewCampaigns,
  setNewCampaigns,
} from '../redux/campaignPreviews';
import withAlert from '../hoc/withAlert';
import StandardLayout from '../layout/StandardLayout';
import CampaignPreviewShelf from '../components/CampaignPreviewShelf';
import failedKickstarterCampaigns from '../images/failedKickstarterCampaigns.png'
import textStyles from '../styles/text';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core';
import pageStyles from '../styles/page';

const image = css`
  max-width: 500px;
`;

const sections = [
  {
    header: "What is Dackie?",
    paragraphs: [
      "Dackie is a crowdfunding platform that gets incentives right.",
      [
        "It's based on the idea of a ",
        {
          href: "https://en.wikipedia.org/wiki/Assurance_contract#Dominant_assurance_contracts",
          text: "dominant assurance contract"
        },
        " originally conceived by the economist ",
        {
          href: "https://en.wikipedia.org/wiki/Alex_Tabarrok",
          text: "Alex Tabarrok",
        },
        "."
      ]
    ]
  },
  {
    header: "How many times have you seen crowdfunding campaigns like these?",
    image: failedKickstarterCampaigns,
    paragraphs: [
      "These projects would be dead in the water on other platforms. Not on Dackie."
    ]
  },
  {
    header: "How does it work?",
    paragraphs: [
      "Creators must put up a stake, say 10% of what they are asking for funding.",
      "So let’s say you’re asking for $10,000. You put up a $1,000 stake.",
      "If enough people back you and you hit your funding goal, you get your $10,000 and your stake back.",
      "If you don’t hit your funding goal, your $1,000 stake is proportionally distributed to the people who did try to back you. If two people -- Alice and Bob -- tried to back you for $50, then they would each receive $500. If Alice tried to back you for $100 and Bob for $50, then Alice would receive $666.67 and Bob would receive $333.33.",
    ]
  },
  {
    header: "Wait, why would I want to do that?",
    paragraphs: [
      "Little differences can add up to monumentally different products.",
      "Getting incentives right is everything.",
      [
        "Well-meaning incentives can often have huge unintended consequences. These are called ",
        {
          href: "https://en.wikipedia.org/wiki/Perverse_incentive",
          text: "perverse incentives",
        },
        "."
      ],
      "Wikipedia has many great examples:",
      '“The British government, concerned about the number of venomous cobras in Delhi, offered a bounty for every dead cobra. Initially, this was a successful strategy; large numbers of snakes were killed for the reward. Eventually, however, enterprising people began to breed cobras for the income. When the government became aware of this, the reward program was scrapped. When cobra breeders set their now-worthless snakes free, the wild cobra population further increased.”',
      "Understanding that incentives are everything is key. With the right incentives, things go well. Everyone is better off. With the wrong incentives, wars break out, civilizations collapse, and everyone dies.",
      "We want to get the incentives right.",
      "Crowdfunding can be hard.",
      "Creators want to hit their goals.",
      "Backers only want to go through the trouble of supporting a campaign if it’s actually going to succeed.",
      "Dackie creates a win-win for both parties.",
      "Do you have to put up some money to make money? Yes! This is a feature, not a bug.",
      "Creators like that people are more incentivized to back their campaign. This should result in a much higher success rate than the typical crowdfunding success rate of 35%. Not having met your funding goal in the first few days doesn’t mean the project is going to fail.",
      "Backers like that they either back a successful campaign or get a nice monetary reward. They also appreciate that the creator has skin in the game when creating a campaign. They know the creator is really thinking everything through before starting a project.",
      "We’re excited to see all the cool things that get built because of Dackie: games, art, blog posts, novels, and hopefully lots of life extension research!",
    ]
  },
  {
    header: "Ready to create a campaign?",
    paragraphs: [
      [
        "Get started ",
        {
          href: "/campaigns/create",
          text: "here",
        },
        "."
      ]
    ]
  },
  {
    header: "Ready to start backing campaigns?",
  }
]

const AboutPage = () => {
  return (
    <StandardLayout>
      {sections.map(section => (
        <>
          <div css={pageStyles.item}>
            <Typography variant="h5">
              {section.header}
            </Typography>
          </div>
          {section.image ? (
            <div css={pageStyles.item}>
              <img css={image} src={section.image} />
            </div>
          ) : null}
          {section.paragraphs && section.paragraphs.map(paragraph => {
            if (Array.isArray(paragraph)) {
              return (
                <div css={pageStyles.item}>
                  <Typography>
                    {paragraph.map(segment => {
                      if (typeof segment === "object" && segment != null) {
                        if (segment.href) {
                          return (
                            <Link
                              variant="body1"
                              href={segment.href}
                              css={textStyles.link}
                              target="_blank"
                            >
                              {segment.text}
                            </Link>
                          )
                        }
                      }
                      return segment;
                    })}
                  </Typography>
                </div>
              )
            }
            return (
              <div css={pageStyles.item}>
                <Typography>
                  {paragraph}
                </Typography>
              </div>
            );
        })}
          {section.button ? (
            <div css={pageStyles.item}>
              <Link href={section.button.href}>
                <Button variant="outlined" onClick={null}>
                  {section.button.text}
                </Button>
              </Link>
            </div>
          ) : null}
        </>
      ))}

      <CampaignPreviewShelf
        title={'New Campaigns'}
        subtitle={'Be the first supporter!'}
        seeMoreText={'See all'}
        seeMoreHref={'/campaigns/new'}
        fetchCampaigns={getNewCampaigns}
        isLoadingSelector={getIsLoadingNewCampaigns}
        setIsLoadingDispatch={setIsLoadingNewCampaigns}
        campaignListSelector={getNewCampaignsList}
        setCampaignDataDispatch={setNewCampaigns}
        hasHorizontalRule={false}
      />
    </StandardLayout>
  );
}

export default withAlert(AboutPage);

import React, { useEffect, useRef, useState } from 'react';

import { getTimeRemaining } from '../utils/campaignUtils';
import { getTimeRemainingString } from '../utils/format';

import Typography from '@material-ui/core/Typography';

const TimeRemaining = ({
  campaignId,
  startDate,
  duration,
}) => {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(startDate, duration))
  const timeoutHandler = useRef(null);

  useEffect(() => {
    const setNewTimeRemaining = () => {
      // console.log('setNewTimeRemaining')
      const newTimeRemaining = getTimeRemaining(startDate, duration)
      if (newTimeRemaining) {
        setTimeRemaining(getTimeRemaining(startDate, duration))
        const newTimeoutHandler = setTimeout(() => setNewTimeRemaining(), 1000);
        timeoutHandler.current = newTimeoutHandler
      } else {
        if (timeoutHandler.current) {
          clearTimeout(timeoutHandler.current)
        }
        // Hard refresh to the updated finished campaign page
        // Could also have all components subscribed to isFinished in Redux
        // and they would all update at the same time
        window.location = `${window.location.origin}/campaign/${campaignId}`
      }
    }
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }
    setNewTimeRemaining();
    return () => {
      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
      }
    }
  }, [])

  return (
    <>
      <Typography variant="h5">
        {getTimeRemainingString(timeRemaining)}
      </Typography>
    </>
  );
}

TimeRemaining.defaultProps = {
  shouldCountDown: true,
};

export default TimeRemaining;

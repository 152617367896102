import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import {
  getCampaignRatingFormField,
  setCampaignRatingFormValue,
  setCampaignRatingFormError,
  getCampaignRatingTemplateField,
} from '../redux/campaignRatings';
import {
  CAMPAIGN_RATING_TYPES,
} from '../utils/campaignRatings';
import NumberSelector from '../components/NumberSelector';
import SwitchSelector from '../components/SwitchSelector';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import formStyles from '../styles/form';

const CampaignRatingField = ({
  campaignId,
  dataKey,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const templateData = useSelector(getCampaignRatingTemplateField(dataKey));
  const type = get(templateData, 'type');
  const description = get(templateData, 'description');
  const min = get(templateData, 'min');
  const max = get(templateData, 'max');
  const maxLength = get(templateData, 'maxLength');

  const fieldData = useSelector(getCampaignRatingFormField(campaignId, dataKey));
  const error = get(fieldData, 'error');
  const value = get(fieldData, 'value');

  // Initialize bools as false
  useEffect(() => {
    if (type === CAMPAIGN_RATING_TYPES.BOOL) {
      dispatch(setCampaignRatingFormValue(campaignId, dataKey, false));
    }
  }, []);

  const onChangeBool = (e) => {
    dispatch(setCampaignRatingFormValue(campaignId, dataKey, e.target.checked))
  }

  const onChangeValue = (newValue) => {
    dispatch(setCampaignRatingFormValue(campaignId, dataKey, newValue));
  }

  const getOnChangeNumberHandler = (isFloat) => (e) => {
    dispatch(setCampaignRatingFormError(campaignId, dataKey, null));
    const newValue = e.target.value;
    const isBlank = newValue === '';
    const isValid = isBlank || (newValue >= min && newValue <= max);
    if (isValid) {
      const parsedValue = isFloat ? parseFloat(value) : parseInt(value);
      dispatch(setCampaignRatingFormValue(dataKey, isBlank ? newValue : parsedValue));
    } else {
      const errorMessage = `Value must be between ${min} and ${max}`;
      dispatch(setCampaignRatingFormError(campaignId, dataKey, errorMessage));
    }
  }

  const onChangeString = (e) => {
    dispatch(setCampaignRatingFormError(campaignId, dataKey, null));
    dispatch(setCampaignRatingFormValue(campaignId, dataKey, e.target.value));
  }

  const getFieldComponent = () => {
    switch (type) {
      case CAMPAIGN_RATING_TYPES.BOOL:
        return (
          <SwitchSelector
            value={value}
            onChange={onChangeBool}
            disabled={isLoading}
          />
        )
      case CAMPAIGN_RATING_TYPES.INT:
        return (
          <NumberSelector
            value={value}
            onChange={onChangeValue}
            error={!!error}
            disabled={isLoading}
            minValue={min}
            maxValue={max}
            helperText={error || ''}
          />
        );
      case CAMPAIGN_RATING_TYPES.FLOAT:
        return (
          <TextField
            variant="outlined"
            helperText={error || ''}
            name={dataKey}
            value={value}
            type="number"
            onChange={getOnChangeNumberHandler(true)}
            error={!!error}
            disabled={isLoading}
            inputProps={{ min, max }}
            css={formStyles.partialWidth}
          />
        );
      default:
        return (
          <TextField
            variant="outlined"
            helperText={error || ''}
            name={dataKey}
            value={value}
            onChange={onChangeString}
            error={!!error}
            inputProps={{ maxLength }}
            disabled={isLoading}
          />
        )
    }
  }
  return (
    <>
      <div css={formStyles.field}>
        <div css={formStyles.label}>
          <Typography variant="h6">
            {description}
          </Typography>
        </div>
        {getFieldComponent()}
      </div>
    </>
  );
}

export default CampaignRatingField;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get'
import { getMyProfile, updateBio } from '../api/users';
import withAlert from '../hoc/withAlert';
import {
  setAlert,
} from '../redux/alerts';
import {
  getBio,
  setBio,
  getIsLoadingProfile,
  getIsLoadingBio,
  setIsLoadingBio,
} from '../redux/auth';
import { row, getCol, marginBottomOnXs } from '../utils/layout';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import formStyles from '../styles/form';
import pageStyles from '../styles/page';

const BioWidget = ({
  cssProp,
}) => {
  const dispatch = useDispatch();
  const bioFromDB = useSelector(getBio);
  const isLoadingProfile = useSelector(getIsLoadingProfile);
  const isLoadingBio = useSelector(getIsLoadingBio);
  const [isEditing, setIsEditing] = useState(false);
  const [newBio, setNewBio] = useState(bioFromDB || '');
  const [bioError, setBioError] = useState(null);
  const [shouldReload, setShouldReload] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      const profileFromDB = await getMyProfile();
      const bio = get(profileFromDB, 'bio', null);
      dispatch(setBio(bio));
      dispatch(setIsLoadingBio(false));
    }
    if (shouldReload !== null) {
      fetchProfileData();
    }
  }, [shouldReload])

  const onBioChange = (e) => {
    setBioError(null);
    setNewBio(e.target.value);
  }

  const onClickEdit = () => {
    setIsEditing(!isEditing);
  }

  const onClickSave = async () => {
    dispatch(setIsLoadingBio(true));
    const savedBio = await updateBio(newBio);
    if (savedBio) {
      dispatch(setAlert('Bio saved successfully!'))
      setShouldReload(!shouldReload);
    } else {
      dispatch(setIsLoadingBio(false));
    }
    setNewBio('');
    setIsEditing(false);
  }

  return !isLoadingProfile ? (
    <div css={cssProp}>
      {bioFromDB ? (
        <div css={row}>
          <div css={[getCol({ xs: 12 }), marginBottomOnXs]}>
            <Typography>
              {bioFromDB}
            </Typography>
          </div>
          <div css={getCol({ xs: 12 })}>
            <Button
              variant="outlined"
              onClick={onClickEdit}
              disabled={isLoadingBio}
            >
              {!isEditing ? 'Edit' : 'Cancel'}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Typography css={pageStyles.title}>
            Write your bio here. Bios show up on your public profile page and on campaigns you create.
          </Typography>
        </>
      )}
      {!bioFromDB || isEditing ? (
        <div css={[row, formStyles.popoutField]}>
          <div css={[getCol({ xs: 12, sm: 8, md: 4 }), marginBottomOnXs]}>
            <TextField
              variant="outlined"
              label="Bio"
              name="newBio"
              value={newBio}
              onChange={onBioChange}
              error={!!bioError}
              helperText={bioError || null}
              disabled={isLoadingBio}
              multiline
              rows={4}
              inputProps={{ maxLength: 500 }}
              css={formStyles.fullWidth}
            />
          </div>
          <div css={getCol({ xs: 12, sm: 4, md: 2 })}>
            <Button
              variant="outlined"
              onClick={onClickSave}
              disabled={isLoadingBio || !newBio}
            >
              Save
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  ) : (
      <div>
        <Typography>Loading...</Typography>
      </div>
  )
}

export default withAlert(BioWidget);

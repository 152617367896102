import { css } from '@emotion/core';
import { BREAKPOINTS} from '../utils/layout';

const item = css`
  margin-bottom: 20px;
`;

const title = css`
  margin-bottom: 10px;
`;

const homePageItem = css`
  margin: 20px 0;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin: 40px 0;
  }
`;

const marginBottomOnMobile = css`
  margin-bottom: 16px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin-bottom: 0;
  }
`;

const indent = css`
  margin-left: 16px;
`;

export default {
  item,
  homePageItem,
  title,
  marginBottomOnMobile,
  indent,
}
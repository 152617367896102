import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import StandardLayout from '../layout/StandardLayout';
import get from 'lodash/get'
import withAuth from '../hoc/withAuth';
import { getProfile } from '../api/users';
import { BREAKPOINTS } from '../utils/layout';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const CREATED_CAMPAIGN_TABLE_COLUMNS = [
  { key: 'name', title: 'Name' },
  { key: 'status', title: 'Status' },
  { key: 'endDate', title: 'End date' },
  { key: 'fundsTotal', title: 'Total funds' },
  { key: 'fundingGoal', title: 'Funding goal' },
  { key: 'stake', title: 'Stake' },
];

const PublicProfileCreatedCampaignsTable = ({
  campaigns
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {CREATED_CAMPAIGN_TABLE_COLUMNS.map(column => (
              <TableCell key={column.key}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map((campaign, i) => {
            const name = get(campaign, 'name');
            const status = get(campaign, 'status');
            const endDate = get(campaign, 'endDate');
            const fundsTotal = get(campaign, 'fundsTotal');
            const fundingGoal = get(campaign, 'fundingGoal');
            const stake = get(campaign, 'stake');
            const data = {
              name,
              status: status === 'archived' ? 'Finished' : 'In progress',
              endDate: endDate ? new Date(endDate).toLocaleDateString() : "N/A",
              fundsTotal: fundsTotal ? `$${fundsTotal}` : "$0",
              fundingGoal: fundingGoal ? `$${fundingGoal}` : "$0",
              stake: stake ? `$${stake}` : "$0",
            };
            return (
              <TableRow key={i}>
                {CREATED_CAMPAIGN_TABLE_COLUMNS.map(column => (
                  <TableCell key={column.key}>
                    {data[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PublicProfileCreatedCampaignsTable;

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
// import cyan from '@material-ui/core/colors/cyan'

import EcoIcon from '@material-ui/icons/Eco';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'; // light bulb
// import GradeIcon from '@material-ui/icons/Grade';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const flexbox = css`
  display: flex;
`;

const defaultCursor = css`
  cursor: default;
`;

const marginRight = css`
  margin-right: 2px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const fundsIcon = css`
  color: ${green[500]};
`;

const campaignsIcon = css`
  color: ${amber[500]};
`;

/*
const ratingsIcon = css`
  color: ${cyan[500]};
`;
*/

const ReputationWidget = ({
  reputation,
  typographyVariant,
  iconSize,
}) => {
  const funds = get(reputation, 'fundedCampaignsCount', null);
  const campaigns = get(reputation, 'publishedCampaignsCount', null);
  const ratingsCount = get(reputation, 'successfulFundedCampaignsRatedCount', null);
  const successfulCount = get(reputation, 'successfulFundedCampaignsCount')
  const ratings = ratingsCount && successfulCount ? parseInt(ratingsCount * 100 / successfulCount) : 0;
  const isValid = funds !== null && campaigns !== null && ratings !== null;

  const fundsTooltipText = `${funds} ${funds !== 1 ? 'campaigns' : 'campaign'} funded`;
  const campaignsTooltipText = `${campaigns} ${campaigns !== 1 ? 'campaigns' : 'campaign'} created`;
  // const ratingsTooltipText = `${ratings}% of successful campaigns rated`;

  return isValid ? (
    <div css={flexbox}>
      <Tooltip title={fundsTooltipText} css={marginRight}>
        <div css={flexbox}>
          <EcoIcon fontSize={iconSize} css={fundsIcon} />
          <Typography variant={typographyVariant} css={defaultCursor}>
            {funds}
          </Typography>
        </div>
      </Tooltip>
      <Tooltip title={campaignsTooltipText} css={marginRight}>
        <div css={flexbox}>
          <EmojiObjectsIcon fontSize={iconSize} css={campaignsIcon} />
          <Typography variant={typographyVariant} css={defaultCursor}>
            {campaigns}
          </Typography>
        </div>
      </Tooltip>
      {/*<Tooltip title={ratingsTooltipText} css={marginRight}>
        <div css={flexbox}>
          <GradeIcon fontSize={iconSize} css={ratingsIcon} />
          <Typography variant={typographyVariant} css={defaultCursor}>
            {`${ratings}%`}
          </Typography>
        </div>
      </Tooltip>*/}
    </div>
  ): null;
};

ReputationWidget.propTypes = {
  reputation: PropTypes.shape({
    fundedCampaignsCount: PropTypes.number,
    publishedCampaignsCount: PropTypes.number,
    successfulFundedCampaignsRatedCount: PropTypes.number,
    successfulFundedCampaignsCount: PropTypes.number,
  }).isRequired,
};

ReputationWidget.defaultProps = {
  typographyVariant: 'subtitle2',
  iconSize: 'small',
  iconColor: 'primary',
};

export default ReputationWidget;

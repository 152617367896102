import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomePageItemLayout from '../layout/HomePageItemLayout';
import { row, getCol, hideOnMobileXs, BREAKPOINTS } from '../utils/layout'
import CampaignPreviewShelfTitle from './CampaignPreviewShelfTitle';
import CampaignPreview from './CampaignPreview';
import EmptyCampaignPreview from './EmptyCampaignPreview';

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const horizontalRule = css`
  margin: 0 16px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin: 0 32px;
  }
`;

const campaignLoadingContainer = css`
  text-align: center;
`;

const campaignPreviewsContainer = css`
  flex-wrap: none;
`;

const CampaignPreviewShelf = ({
  title,
  subtitle,
  seeMoreText,
  seeMoreHref,
  fetchCampaigns,
  isLoadingSelector,
  setIsLoadingDispatch,
  campaignListSelector,
  setCampaignDataDispatch,
  hasHorizontalRule,
  emptyMessage,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  let campaignList = useSelector(campaignListSelector);
  const [displayOffset, setDisplayOffset] = useState(0);

  useEffect(() => {
    async function loadCampaigns() {
      dispatch(setIsLoadingDispatch(true));
      const campaigns = await fetchCampaigns();
      if (campaigns) {
        dispatch(setCampaignDataDispatch(campaigns));
      }
      dispatch(setIsLoadingDispatch(false));
    }
    loadCampaigns();
  }, []);

  return (
    <>
      {hasHorizontalRule ? (
        <hr css={horizontalRule} />
      ) : null}
      <HomePageItemLayout>
        {isLoading ? (
          <div css={[pageStyles.homePageItem, campaignLoadingContainer]}>
            <Typography>Loading campaigns...</Typography>
          </div>
        ) : (
          <div css={pageStyles.homePageItem}>
            {campaignList && campaignList.length > 0 ? (
              <>
                <CampaignPreviewShelfTitle
                  title={title}
                  subtitle={subtitle}
                  seeMoreText={seeMoreText}
                  seeMoreHref={seeMoreHref}
                  campaignListLength={campaignList ? campaignList.length : 0}
                  displayOffset={displayOffset}
                  setDisplayOffset={setDisplayOffset}
                />
                <div css={[row, campaignPreviewsContainer]}>
                  {campaignList.slice(displayOffset, displayOffset + 3).map((campaignId) => {
                    return (
                      <div
                        css={[getCol({ xs: 12, sm: 4 }), pageStyles.marginBottomOnMobile]}
                        key={campaignId}
                      >
                        <CampaignPreview
                          campaignId={campaignId}
                        />
                      </div>
                    );
                  })}
                  {campaignList.length < 3 ? (
                    <div css={[getCol({ xs: 12, sm: 4 }), hideOnMobileXs]}>
                      <EmptyCampaignPreview />
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <EmptyCampaignPreview
                message={emptyMessage}
              />
            )}
          </div>
        )}
      </HomePageItemLayout>
    </>
  );
}

CampaignPreviewShelf.defaultProps = {
  hasHorizontalRule: true,
  emptyMessage: 'No campaigns are active at this time. Click here to start your own!',
}

export default CampaignPreviewShelf;

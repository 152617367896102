const SET_IS_LOADING = 'CAMPAIGN/SET_IS_LOADING';
const SET_CAMPAIGN = 'CAMPAIGN/SET_CAMPAIGN';

const initialState = {
  isLoading: false,
  data: {},
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_CAMPAIGN:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
      }
    default:
      return state;
  }
}

export function setIsLoading(isLoading) {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}

export function setCampaign(campaign) {
  return {
    type: SET_CAMPAIGN,
    payload: campaign || {},
  }
}

export function getIsLoading(state) {
  return state.campaigns.isLoading;
}

export function getCampaign(campaignId) {
  return (state) => state.campaigns.data[campaignId];
}

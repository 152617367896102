import { css } from '@emotion/core';
import { BREAKPOINTS } from '../utils/layout';

const flexboxOnDesktop = css`
  @media (min-width: ${BREAKPOINTS.SM}px) {
    display: flex;
    align-items: center;
  }
`;

const inlineOnDesktop = css`
  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin-left: 16px;
  }
`;

const cardElement = css`
  padding: 18.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 4px;
  margin-bottom: 8px;
  border-color: rgba(0, 0, 0, 0.54);
  min-width: 400px;
  max-width: 500px;
`;

export default {
  flexboxOnDesktop,
  inlineOnDesktop,
  cardElement,
};
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getUserDisplayName,
  getAuthIsLoading,
} from '../redux/auth';
import FirebaseUIPage from '../pages/FirebaseUIPage';

const withAuth = Component => props => {
  const authIsLoading = useSelector(getAuthIsLoading);
  const userDisplayName = useSelector(getUserDisplayName);
  const isAuthenticated = userDisplayName;

  if (authIsLoading) {
    return null;
  } else {
    if (isAuthenticated) {
      return <Component {...props} />;
    }
    return <FirebaseUIPage shouldRedirect={false} />;
  }
}

export default withAuth;

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import StandardLayout from '../layout/StandardLayout';
import get from 'lodash/get'
import withAuth from '../hoc/withAuth';
import { getProfile } from '../api/users';
import { BREAKPOINTS } from '../utils/layout';
import PublicProfileCreatedCampaignsTable from '../components/PublicProfileCreatedCampaignsTable';
import PublicProfileFundedCampaignsTable from '../components/PublicProfileFundedCampaignsTable';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import pageStyles from '../styles/page';

// TODO: Make endpoints for fetching someone else's campaigns and funds
// Then put into ItemsTable
const PublicProfilePage = () => {
  const match = useRouteMatch('/profile/:id');
  const profileId = match.params.id;

  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const username = get(profile, 'user.username');
  const bio = get(profile, 'user.bio');
  const createdCampaigns = get(profile, 'createdCampaigns');
  const fundedCampaigns = get(profile, 'fundedCampaigns');

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      const fetchedProfile = await getProfile(profileId);
      setProfile(fetchedProfile);
      setIsLoading(false);
    }
    fetchProfile();
  }, [profileId]);

  if (isLoading) {
    return (
      <StandardLayout>
        <Typography>Loading...</Typography>
      </StandardLayout>
    )
  }

  return (
    <StandardLayout>
      <Typography variant="h4" css={pageStyles.title}>
        {username}
      </Typography>
      {bio ? (
        <div css={pageStyles.item}>
          <Typography>{bio}</Typography>
        </div>
      ) : null}
      <div css={pageStyles.item}>
        <Typography variant="h5" css={pageStyles.title}>
          Created campaigns
        </Typography>
        {createdCampaigns && createdCampaigns.length > 0 ? (
          <PublicProfileCreatedCampaignsTable
            campaigns={createdCampaigns}
          />
        ) : (
          <Typography>
            No campaigns created yet!
          </Typography>
        )}
      </div>
      <div css={pageStyles.item}>
        <Typography variant="h5" css={pageStyles.title}>
          Funded campaigns
        </Typography>
        {fundedCampaigns && fundedCampaigns.length > 0 ? (
          <PublicProfileFundedCampaignsTable
            campaigns={fundedCampaigns}
          />
        ) : (
          <Typography>
            No campaigns funded yet!
          </Typography>
        )}
      </div>
    </StandardLayout>
  );
}

export default withAuth(PublicProfilePage);

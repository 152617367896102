import React from 'react';
import { BREAKPOINTS, hideOnMobileXs } from '../utils/layout';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';

const privacyIndicatorContainer = css`
  display: flex;
  align-items: center;
  padding: 4px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: 79px; /** width of icon with "Private" */
  }
`;

const privacyTypography = css`
  cursor: default;
  margin-left: 4px;
`;

const PrivacyIndicator = ({
  isPublic,
  tooltipText,
}) => {
  return (
    <Tooltip title={tooltipText}>
      <div css={privacyIndicatorContainer}>
        {isPublic ? (
          <VisibilityIcon fontSize="small" />
        ) : (
            <VisibilityOffIcon fontSize="small" />
          )}
        <Typography variant="body2" css={[hideOnMobileXs, privacyTypography]}>
          {isPublic ? 'Public' : 'Private'}
        </Typography>
      </div>
    </Tooltip>
  );
}

PrivacyIndicator.defaultProps = {
  tooltipText: 'Privacy'
}

export default PrivacyIndicator;

import { css } from '@emotion/core';

const iconWithLabel = css`
  display: flex;
`;

const inlineIcon = css`
  margin-right: 4px;
`;

export default {
  iconWithLabel,
  inlineIcon,
}
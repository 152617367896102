import { css } from '@emotion/core';
import lightGreen from '@material-ui/core/colors/lightGreen';
import { BREAKPOINTS } from '../utils/layout';

const field = css`
  margin-bottom: 20px;
`;

const label = css`
  margin-bottom: 10px;
`;

const inline = css`
  margin-left: 16px;
`;

const partialWidth = css`
  width: 60%;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: 40%;
  }
`;

const fullWidth = css`
  width: 100%;
`;

const fieldWithButton = css`
  display: flex;
  align-items: center;
`;

const popoutField = css`
  margin-top: 10px;
`;

const button = css`
  background-color: ${lightGreen['300']};
  &:hover {
    background-color: ${lightGreen['500']};
  }
`;

const flexbox = css`
  display: flex;
  justify-content: space-between;
`;

export default {
  field,
  label,
  inline,
  partialWidth,
  fullWidth,
  fieldWithButton,
  popoutField,
  button,
  flexbox,
};

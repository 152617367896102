import React from 'react';
import PropTypes from 'prop-types';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const footer = css`
  display: flex;
  justify-content: space-around;
  flex-shrink: 0;
  padding: 16px;
`;

const Footer = () => (
  <footer css={footer}>
    <Link href="/termsofservice">
      <Typography>Terms of Service</Typography>
    </Link>
    <Link href="/privacypolicy">
      <Typography>Privacy Policy</Typography>
    </Link>
  </footer>
);

export default Footer;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  getUnseenAchievements,
  getStaticAchievements,
  markAchievementsAsSeen,
} from '../api/achievements';
import {
  getUserId,
} from '../redux/auth';
import {
  setUnseenAchievementKeys,
  removeUnseenAchievementKey,
  getNextUnseenAchievementKey,
  getStaticAchievement,
  getStaticAchievementKeys,
  setStaticAchievements,
} from '../redux/achievements';

import Snackbar from '@material-ui/core/Snackbar';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';

const DEFAULT_ACHIEVEMENT_DURATION_MS = 3000;

// Check for achievements on page load
// If you have a new one, show the alert
const withAchievementsAlert = Component => props => {
  const [shouldClose, setShouldClose] = useState(false);
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const nextUnseenAchievementKey = useSelector(getNextUnseenAchievementKey);

  const staticAchievementKeys = useSelector(getStaticAchievementKeys);
  const staticAchievement = useSelector(getStaticAchievement(nextUnseenAchievementKey));
  const name = get(staticAchievement, 'name');
  const description = get(staticAchievement, 'description');

  useEffect(() => {
    async function fetchUnseenAchievements() {
      const achievementKeyList = await getUnseenAchievements();
      console.log(achievementKeyList);
      dispatch(setUnseenAchievementKeys(achievementKeyList))
    }
    async function fetchStaticAchievements() {
      const staticAchievements = await getStaticAchievements();
      dispatch(setStaticAchievements(staticAchievements));
    }
    if (userId) {
      fetchUnseenAchievements();
      if (!staticAchievementKeys || staticAchievementKeys.length === 0) {
        fetchStaticAchievements();
      }
    }
  }, [userId]);

  const getOnCloseHandler = (achievementKey) => {
    return () => {
      setShouldClose(true);
      console.log(achievementKey)
      markAchievementsAsSeen([achievementKey]);

      // Give snackbar time to close before changing the message
      setTimeout(() => {
        dispatch(removeUnseenAchievementKey(achievementKey));
      }, 1000);

      // Wait to mark in Redux so there is time between alerts
      setTimeout(() => {
        setShouldClose(false)
      }, 2000);
    }
  }

  return (
    <>
      <Snackbar
        open={!!name && !shouldClose}
        autoHideDuration={DEFAULT_ACHIEVEMENT_DURATION_MS}
        onClose={getOnCloseHandler(nextUnseenAchievementKey)}
        message={`${name}: ${description}`}
      />
      <Component {...props} />
    </>
  );
}

export default withAchievementsAlert;

import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthIsLoading, getUserDisplayName } from '../redux/auth';

import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import iconStyles from '../styles/icon';
import textStyles from '../styles/text';

const navBarStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  @media (min-width: 800px) {
    height: 76px;
  }
`;

const navBarLeftStyle = css`
  display: flex;
`;

const navBarRightStyle = css`
  display: flex;
`;

const whiteText = css`
  color: #ffffff;
`;

const displayOnlyOnDesktop = css`
  display: none;
  @media (min-width: 800px) {
    display: block;
  }
`;

const loginButtonCSS = css`
  color: #ffffff;
  background-color: ${green['500']};
  &:hover {
    background-color: ${green['700']};
  }
`;

const NAV_BAR_ICONS = {
  HOME: HomeIcon,
  ABOUT: InfoIcon,
  STATS: ShowChartIcon,
  MY_CAMPAIGNS: PersonIcon,
  FUNDED_CAMPAIGNS: GroupIcon,
  PROFILE: AccountCircleIcon,
  // LOGIN: ,
}

const NavBarLink = ({
  href,
  Icon,
  label,
}) => {
  return (
    <RRLink to={href} css={[iconStyles.iconWithLabel, textStyles.navBarLink]}>
      <Icon css={[iconStyles.inlineIcon, whiteText]} />
      <div css={displayOnlyOnDesktop}>
        <Typography>
          {label}
        </Typography>
      </div>
    </RRLink>
  )
}

const NavBar = () => {
  const isLoading = useSelector(getAuthIsLoading);

  const userDisplayName = useSelector(getUserDisplayName)
  const isAuthenticated = userDisplayName;

  return (
    <div>
      <AppBar position="sticky">
        <div css={navBarStyle}>
          <div css={navBarLeftStyle}>
            <NavBarLink
              href={'/'}
              Icon={NAV_BAR_ICONS.HOME}
              label={'Dackie'}
            />
            <NavBarLink
              href={'/about'}
              Icon={NAV_BAR_ICONS.ABOUT}
              label={'About'}
            />
            {/*<NavBarLink
              href={'/stats'}
              Icon={NAV_BAR_ICONS.STATS}
              label={'Stats'}
            />*/}
          </div>
          {/** TODO: Search campaigns */}
          <div css={navBarRightStyle}>
            {!isLoading ? (
              <>
                {isAuthenticated ? (
                  <>
                    <NavBarLink
                      href={'/campaigns/funded'}
                      Icon={NAV_BAR_ICONS.FUNDED_CAMPAIGNS}
                      label={`Funded Campaigns`}
                    />
                    <NavBarLink
                      href={'/campaigns'}
                      Icon={NAV_BAR_ICONS.MY_CAMPAIGNS}
                      label={'My Campaigns'}
                    />
                    <NavBarLink
                      href={'/profile'}
                      Icon={NAV_BAR_ICONS.PROFILE}
                      label={'Profile'}
                    />
                  </>
                ) : (
                  <RRLink to={'/login'}>
                    <Button
                      variant="outlined"
                      css={loginButtonCSS}
                    >
                      {'Log in'}
                    </Button>
                  </RRLink>
                )}
              </>
            ) : null}
          </div>
        </div>
      </AppBar>
    </div>
  );
};

export default NavBar;

import React, { useState } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';

const ItemsTableRow = ({
  id,
  index,
  shouldNumberRows,
  getItemByIdSelector,
  fieldsToShow,
  fieldProcessingFuncs,
  fieldLinks,
  actionButtons,
  // Reload only one item, requires endpoint to load one item
  // reloadRowCallback,
  // Reload page
  reloadPageCallback,
  // Reload from page 1 because action changes pagination, e.g. delete a row
  // reloadTableCallback,
}) => {
  const item = useSelector(getItemByIdSelector(id));
  const [isRowDisabled, setIsRowDisabled] = useState(false);

  return (
    <TableRow>
      {shouldNumberRows ? (
        <TableCell>
          <Typography>{index}</Typography>
        </TableCell>
      ) : null}
      {map(fieldsToShow, (field, i) => {
        const processField = get(fieldProcessingFuncs, field);
        const processedFieldData = processField ? processField(item) : get(item, field);
        const getHref = get(fieldLinks, `[${field}].getHref`);
        return (
          <TableCell key={`field-${index}-${i}`}>
            {getHref ? (
              <RRLink to={getHref(item)}>
                <Typography>{`${processedFieldData}`}</Typography>
              </RRLink>
            ) : (
                <Typography>{`${processedFieldData}`}</Typography>
              )}
          </TableCell>
        );
      })}
      {map(actionButtons, (actionButton, i) => {
        const text = get(actionButton, 'text');
        const getText = get(actionButton, 'getText');

        const getHref = get(actionButton, 'getHref');
        const href = getHref ? getHref(item) : null;

        // onClickHandler must be async 
        const onClickHandler = get(actionButton, 'onClickHandler');
        // const shouldReloadRowOnClick = get(actionButton, 'shouldReloadRowOnClick');
        const shouldReloadPageOnClick = get(actionButton, 'shouldReloadPageOnClick');
        // const shouldReloadTableOnClick = get(actionButton, 'shouldReloadTableOnClick');
        const onClick = onClickHandler ? async () => {
          setIsRowDisabled(true);
          await onClickHandler(item);
          if (shouldReloadPageOnClick && reloadPageCallback && typeof reloadPageCallback === 'function') {
            reloadPageCallback();
          }
        } : null;

        const getIsDisabled = get(actionButton, 'getIsDisabled');
        const isDisabled = getIsDisabled ? getIsDisabled(item) : false;

        const getIsDisplayed = get(actionButton, 'getIsDisplayed');
        const isDisplayed = getIsDisplayed ? getIsDisplayed(item) : true;

        return (
          <TableCell key={`actionButton-${index}-${i}`}>
            {isDisplayed ? (
              <Button
                variant="outlined"
                disabled={isDisabled || isRowDisabled}
                onClick={onClick}
                href={href}
              >
                {getText ? getText(item) : text}
              </Button>
            ) : null}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

ItemsTableRow.defaultProps = {
  shouldNumberRows: true,
  fieldsToShow: ['name'],
  fieldProcessingFuncs: {},
  fieldLinks: {},
  actionButtons: [],
}

export default ItemsTableRow;

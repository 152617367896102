import { combineReducers } from 'redux';
import achievements from './achievements';
import alerts from './alerts';
import auth from './auth';
import campaigns from './campaigns';
import campaignFunds from './campaignFunds';
import campaignPreviews from './campaignPreviews';
import campaignRatings from './campaignRatings';
import draftCampaign from './draftCampaign';
import myCampaigns from './myCampaigns';
import stripe from './stripe';
import userFunds from './userFunds';

const rootReducer = combineReducers({
  achievements,
  alerts,
  auth,
  campaigns,
  campaignFunds,
  campaignPreviews,
  campaignRatings,
  draftCampaign,
  myCampaigns,
  stripe,
  userFunds,
});
export default rootReducer;

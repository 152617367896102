import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { db } from '../firebase';

export async function getUserById(id) {
  return db.collection('users').doc(id).get().then((doc) => {
    if (doc.exists) {
      return doc.data();
    } else {
      return null;
    }
  })
}

export async function getProfile(userId) {
  try {
    const res = await firebase.functions().httpsCallable('getProfile')({
      userId,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getMyProfile() {
  try {
    const res = await firebase.functions().httpsCallable('getMyProfile')();
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export async function updateUsername(username) {
  try {
    const res = await firebase.functions().httpsCallable('updateUsername')({
      username
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export async function updateBio(bio) {
  try {
    const res = await firebase.functions().httpsCallable('updateBio')({
      bio,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export async function createExternalAccount(tokenId) {
  try {
    const res = await firebase.functions().httpsCallable('createExternalAccount')({
      tokenId
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export async function makeExternalAccountDefault(id) {
  try {
    const res = await firebase.functions().httpsCallable('makeExternalAccountDefault')({
      id
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export async function deleteExternalAccount(id) {
  try {
    const res = await firebase.functions().httpsCallable('deleteExternalAccount')({
      id
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export async function payOutBalance() {
  try {
    const res = await firebase.functions().httpsCallable('payOutAccountBalance')();
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

export default {
  getUserById,
  getMyProfile,
  updateUsername,
  updateBio,
  createExternalAccount,
  makeExternalAccountDefault,
  deleteExternalAccount,
  payOutBalance,
};

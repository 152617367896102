import React from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import textStyles from '../styles/text';

const messageContainer = css`
  margin-bottom: 10px;
  text-align: center;
`;

const buttonContainer = css`
  text-align: center;
`;

const EmptyCampaignPreview = ({
  message
}) => {
  return (
    <div>
      <div css={messageContainer}>
        <Typography>
          {message}
        </Typography>
      </div>
      <div css={buttonContainer}>
        <Link href="/campaigns/create" css={textStyles.buttonLink}>
          <Button variant="outlined">
            Create a campaign
          </Button>
        </Link>
      </div>
    </div>
  );
}

EmptyCampaignPreview.defaultProps = {
  message: 'Click here to start a campaign!'
}

export default EmptyCampaignPreview;

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import get from 'lodash/get';

export async function getCampaignRatingByCampaignId(campaignId) {
  try {
    const res = await firebase.functions().httpsCallable('getCampaignRatingByCampaignId')({
      campaignId,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: true };
  }
}

export async function createCampaignRating(campaignId, campaignRatingData) {
  try {
    const res = await firebase.functions().httpsCallable('createCampaignRating')({
      campaignId,
      campaignRatingData,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import { getStaticAchievements } from '../api/achievements';
import {
  getStaticAchievementKeys,
  setStaticAchievements,
} from '../redux/achievements';
import AchievementCard from '../components/AchievementCard';

import Grid from '@material-ui/core/Grid';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';

const AchievementsGrid = () => {
  const dispatch = useDispatch();
  const staticAchievementKeys = useSelector(getStaticAchievementKeys);

  useEffect(() => {
    const fetchStaticAchievements = async () => {
      const staticAchievements = await getStaticAchievements();
      dispatch(setStaticAchievements(staticAchievements));
    }
    if (!staticAchievementKeys || staticAchievementKeys.length === 0) {
      fetchStaticAchievements();
    }
  }, []);

  return (
    <Grid container>
      {map(staticAchievementKeys, (achievementKey) => {
        return (
          <AchievementCard
            key={achievementKey}
            achievementKey={achievementKey}
          />
        );
      })}
    </Grid>
  );
}

export default AchievementsGrid;

import { css } from '@emotion/core';

/**
 * Smallest screen size: 320
 * Phones are typically smaller than 440
 */

const XS_BREAKPOINT = 0;
const SM_BREAKPOINT = 600;
const MD_BREAKPOINT = 960;
const LG_BREAKPOINT = 1280;
const XL_BREAKPOINT = 1920;
export const BREAKPOINTS = {
  XS: XS_BREAKPOINT,
  SM: SM_BREAKPOINT,
  MD: MD_BREAKPOINT,
  LG: LG_BREAKPOINT,
  XL: XL_BREAKPOINT,
}

export const hideOnMobileXs = css`
  display: none;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    display: block;
  }
`;

export const marginBottomOnXs = css`
  margin-bottom: 8px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin-bottom: 0;
  }
`;

export const getHideOnMobile = (breakpoint) => {
  return css`
    display: none;
    @media (min-width: ${breakpoint}px) {
      display: block;
    }
  `;
}

export const row = css`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const getCol = ({ xs, sm, md, lg }) => {
  const effectiveXs = xs || 12;
  const effectiveSm = sm || xs || 12;
  const effectiveMd = md || sm || xs || 12;
  const effectiveLg = lg || md || sm || xs || 12;
  return css`
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 ${effectiveXs / 12 * 100}%;
    max-width: ${effectiveXs / 12 * 100}%;
    @media (min-width: ${BREAKPOINTS.SM}px) {
      flex: 0 0 ${effectiveSm / 12 * 100}%;
      max-width: ${effectiveSm / 12 * 100}%;
    }
    @media (min-width: ${BREAKPOINTS.MD}px) {
      flex: 0 0 ${effectiveMd / 12 * 100}%;
      max-width: ${effectiveMd / 12 * 100}%;
    }
    @media (min-width: ${BREAKPOINTS.LG}px) {
      flex: 0 0 ${effectiveLg / 12 * 100}%;
      max-width: ${effectiveLg / 12 * 100}%;
    }
  `;
}

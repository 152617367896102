import React from "react";
import StandardLayout from "../layout/StandardLayout";

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const policy = [
  {
    header: "Who We Are",
    paragraphs: [
      `Dackie LLC, its platform Dackie.io, and its parents, subsidiaries, affiliates, agents, representatives,
            consultants, employees, officers, and directors – (collectively, "Dackie," "we," or "us," "our"), provide a
            marketplace to allow users to raise funds for their own research projects, contribute to others’ projects,
            and contract researchers to work on their projects.`,
    ],
  },
  {
    header: "Changes to This Policy",
    paragraphs: [
      `We may update this Privacy Policy ("Policy") from time to time. If we do, we will notify you on the Site
            or by sending you an email of the changes and the date the new Policy goes into effect. The new Policy will
            only apply proactively, never retroactively. Use of the Site after a change implies consent to the new
            Policy.`,
    ],
  },
  {
    header: "How This Policy Applies",
    paragraphs: [
      `This Policy describes the data we collect from you, how we use and protect that data, and your rights and
            choices with respect to that data. Capitalized terms not otherwise defined herein are defined in our Terms
            of Service. By using our website, you acknowledge you have read and understood our Terms of Service and
            this Policy.`
    ],
  },
  {
    header: "The Information We Collect",
    paragraphs: [
      `We collect data from you to allow you to create an account, use the Services, and effectively navigate the
            Site. You may provide the data to us directly (for example, when setting up an account), through
            third-parties (for example, by connecting an external profile to your account with us), or through
            automated means (for example, through the use of cookies). Depending on your use of the Services, we may
            collect the following kinds of information we refer to as "Personal Data":`,
    ],
    bullets: [
      `Information you give us: When you use the Site and any of our sub-domains or applications, we may ask you
            to provide certain personal information depending on your level of use, such as your contact details,
            including your first and last name, email address, country of residence, date of birth, username, and
            password; demographic information, banking and financial information, employment and educational history,
            and business information. This information helps us to comply with our legal obligations and fulfill our
            contractual arrangements with you and other parties and sharing this information may be necessary for us to
            provide our services.`,
      `Information we get from your use of our Services: Dackie collects information about the services you use
            and how you use them, such as how often you access our website, the content you view or post, and your
            preferences. We may gather certain information about your browser, operating system, IP address, and other
            websites from which you accessed the Site. We may also collect your information when you interact with our
            employees on the phone or in person and we record those conversations, either because we are required to do
            so under regulation, or for training purposes so that we can improve our services.`,
      `Information from third parties or publicly available sources: We may also collect your Personal Data from
            third parties, such as a payment providers or third party profiles, when our Site or applications connect
            to them.`,
      `Cookies: Dackie may set and access cookies, which are small files residing on your computer's hard drive
            that contain unique identifiers. To the extent that we have third-party advertisements on the Site, those
            third parties may also place and access cookies on your computer. You can choose to enable or disable
            cookies in your web browser. Disabling or deleting cookies may prevent you from using the full range of
            services available on the Site.`,
    ],
    moreParagraphs: [
      `You have the right to decline to provide us with Personal Data. However, this will likely limit your
            ability to register for an account or use the Services. You can review, change, or remove your Personal
            Data through your account settings.`,
    ],
  },
  {
    header: "Protecting Your Information",
    paragraphs: [
      `We are committed to protecting the privacy and security of your Personal Data. We are a "data controller"
            meaning that we are responsible for deciding how we hold and use your Personal Data. We will protect your
            Personal Data by:`,
    ],
    bullets: [
      `using it lawfully, fairly, and in a transparent way,`,
      `collecting it only for valid and clearly-explained purposes,`,
      `limiting our use of it to the purposes of which we have informed you,`,
      `keeping it accurate and up to date,`,
      `keeping only as long as necessary for the stated purposes, and`,
      `keeping it secure.`,
    ],
    moreParagraphs: [
      `We endeavor do everything reasonable to protect your Personal Data, including through the use of
            encryption technology. However, in using the Site you accept the inherent security implications of engaging
            in transactions over the internet.`,
    ],
  },
  {
    header: "Your Rights",
    paragraphs: [
      `Internet users from different jurisdictions have various rights with respect to their Personal Data. To
            the extent the laws of your jurisdiction apply, you may have the rights to the following:`
    ],
    bullets: [
      `deleting, correcting, or modifying your Personal Data,`,
      `opting-out of direct marketing,`,
      `controlling targeted advertising,`,
      `withdrawing your consent to our use of your Personal Data for purposes to which you have consented,`,
      `controlling our access to your location information, and`,
      `obtaining a copy of the Personal Data you have provided to us.`,
    ],
    moreParagraphs: [
      `If you have concerns about how we collect and use your Personal Data, please contact us using the details
            below. If you are not satisfied with our response, you have the right to bring your grievance to the
            relevant supervisory authority in your jurisdiction.`,
      `If you are a citizen of the European Economic Area, you have the specific right to (i) object to our use
            of your personal information, and (ii) lodge a complaint with your local data protection authority.`,
      `If you are a resident of California, you have the right to request information regarding the disclosure of
            your personal information to third parties for the third parties’ direct marketing purposes. However, we do
            not share your personal information with third parties’ direct market purposes unless you elect for us to
            do so.`,
    ],
  },
  {
    header: "How We Use Information We Collect",
    paragraphs: [
      `We use the personal data we collect to provide you with the Services, optimize the Site, market and
            communicate, and comply with our legal or regulatory obligations. Specifically, we may use Personal Data
            in the following ways:`
    ],
    bullets: [
      `to process and administer payments and contributions in connection with the Services,`,
      `to assess your application to fundraise, verify your disclosures, and provide you with any campaign
            marketing services,`,
      `to confirm your identity and ensure you are eligible to use our Services,`,
      `to conduct anti money-laundering (AML) checks in connection with your transactions on the Site,`,
      `to enable us to comply with our regulatory obligations to governmental authorities,`,
      `to monitor and improve the Site and the Services,`,
      `to contact you to ensure your customer satisfaction and to respond to customer support,`,
      `to enable us to conduct surveys and analysis of our user base, and`,
      `to advertise, publicize, and promote our business.`,
    ],
    moreParagraphs: [
      `We may ask for your consent to use your Personal Data in additional ways and we may update this section
            from time to time, but we will always give you notice before we do so.`
    ],
  },
  {
    header: "Information We Share with Third Parties",
    paragraphs: [
      `We will not disclose Personal Data to third parties except under the following circumstances:`
    ],
    bullets: [
      `We may disclose Personal Data if we are required to do so by law, regulation or the order of court or
            other legitimate government body or arbitration panel.`,
      `We may disclose Personal Data in the event that we intend to enter into a major corporate transaction,
            such as a sale of control of our or another business.`,
      `We may disclose Personal Data in connection with a transaction you conduct through the Site.`,
      `We may disclose your Personal Data to third-party advertising services, such as Google Analytics, running
            advertisements on the Site.`,
      `We may disclose your Personal Data to third party service providers solely in the course of their
            provision of services to us and will take reasonable precautions to ensure that these service providers are
            obliged to keep your Personal Data secure and confidential.`,
    ],
  },
  {
    header: "Children's Privacy",
    paragraphs: [
      `People under 18 are not permitted to use the Site on their own, and children under the age of 13 are not
            permitted to register for an account or use the Services. We will delete any Personal Data from children
            under the age of 13 if we become aware of it.`,
    ],
  },
  {
    header: "Contact Information",
    paragraphs: [
      `If you wish to contact us regarding your Personal Data or have any questions about this Policy you can
            reach us at:`,
      `support@dackie.io`,
    ],
  }
];

const PrivacyPolicy = () => {
  return (
    <StandardLayout>
      <div css={pageStyles.item}>
        <Typography variant="h4">
          Privacy Policy
        </Typography>
      </div>
      {policy.map((section, i) => (
        <div key={i} css={pageStyles.item}>
          <div css={pageStyles.title}>
            <Typography variant="h5">
              {section.header}
            </Typography>
          </div>
          {section.paragraphs.map((paragraph, j) => (
            <Typography key={`${i}-${j}`}>
              {paragraph}
            </Typography>
          ))}
          {section.bullets ? (
            <ul>
              {section.bullets.map((bullet, k) => (
                <li key={`${i}.${k}`}>
                  <Typography>
                    {bullet}
                  </Typography>
                </li>
              ))}
            </ul>
          ) : null}
          {section.moreParagraphs ? (
            section.moreParagraphs.map((paragraph, l) => (
              <Typography key={`${i}-${section.paragraphs.length + l}`}>
                {paragraph}
              </Typography>
            ))
          ) : null}
        </div>
      ))}
    </StandardLayout>
  );
};

export default PrivacyPolicy;

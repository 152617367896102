import React from 'react';

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';

// TODO: Make this page display something
const CampaignRatingInfo = () => {
  return (
    <Typography>
      You have already rated this campaign!
    </Typography>
  );
};

export default CampaignRatingInfo;

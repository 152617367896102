import PropTypes from 'prop-types';
import { BREAKPOINTS } from '../utils/layout';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const homePageItemLayout = css`
  padding: 0 16px;
  @media (min-width: ${BREAKPOINTS.SM}px) {
    max-width: ${BREAKPOINTS.MD}px;
    margin: auto;
  }
`;

const HomePageItemLayout = ({
  children,
}) => {
  return (
    <div css={homePageItemLayout}>
      {children}
    </div>
  );
};

HomePageItemLayout.propTypes = {
  children: PropTypes.any,
};

export default HomePageItemLayout;

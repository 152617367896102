import React from 'react';

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core';

const paymentLine = css`
  display: flex;
  justify-content: space-between;
`;

const paymentTotal = css`
  font-weight: bold;
`;

const calculateTotalFromAmount = (amount) => {
  const amountInCents = amount * 100;
  const total = Math.ceil((amountInCents + 30) / 0.971);
  return total / 100;
}

const PaymentTable = ({
  amount,
}) => {
  const total = calculateTotalFromAmount(amount);
  const fees = total - amount;

  return (
    <>
      <div css={paymentLine}>
        <Typography>Amount</Typography>
        <Typography>{amount.toFixed(2)}</Typography>
      </div>
      <div css={paymentLine}>
        <Typography>Stripe fees</Typography>
        <Typography>{fees.toFixed(2)}</Typography>
      </div>
      <div css={paymentLine}>
        <Typography css={paymentTotal}>Total</Typography>
        <Typography css={paymentTotal}>{total.toFixed(2)}</Typography>
      </div>
    </>
  );
}

export default PaymentTable;
export { calculateTotalFromAmount };

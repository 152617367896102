import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { getCampaignPreview } from '../redux/campaignPreviews';
import ReputationWidget from './ReputationWidget';

import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import textStyles from '../styles/text';

const campaignPreviewCard = css`
  max-width: 400px;
  height: 250px;
  padding: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const campaignPreviewFunds = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const creatorContainer = css`  
  display: flex;
`;

const creatorNameContainer = css`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const progressBar = css`
  margin-bottom: 4px;
`;

const CampaignPreview = ({
  campaignId,
}) => {
  const campaign = useSelector(getCampaignPreview(campaignId));
  const name = get(campaign, 'name');
  const tagline = get(campaign, 'tagline');
  const creatorId = get(campaign, 'creatorId');
  const creatorUsername = get(campaign, 'creatorUsername') || 'Anonymous';
  const creatorReputation = get(campaign, 'creatorReputation');
  const fundsTotal = get(campaign, 'fundsTotal');
  const percent = get(campaign, 'percent');
  const daysRemaining = get(campaign, 'daysRemaining');

  return (
    <Card css={campaignPreviewCard}>
      <div>
        <RRLink to={`/campaign/${campaignId}`} css={textStyles.link}>
          <Typography variant="subtitle1" css={textStyles.overflow}>
            {name}
          </Typography>
        </RRLink>
        <div css={creatorContainer}>
          <Tooltip title={creatorUsername}>
            <div css={creatorNameContainer}>
              <Typography variant="subtitle2" css={textStyles.overflow} display="inline">
                {'by '}
              </Typography>
              <RRLink to={`/profile/${creatorId}`} css={textStyles.link}>
                <Typography variant="subtitle2" display="inline">
                  {creatorUsername}
                </Typography>
              </RRLink>
            </div>
          </Tooltip>
          <ReputationWidget
            reputation={creatorReputation}
          />
        </div>
      </div>
      {tagline ? (
        <Typography variant="subtitle2">{tagline}</Typography>
      ) : null}
      <div>
        <div css={campaignPreviewFunds}>
          <Typography variant="subtitle2">
            {`$${fundsTotal} raised`}
          </Typography>
          <Typography variant="subtitle2">
            {`${percent}%`}
          </Typography>
        </div>
        <LinearProgress
          variant="determinate"
          value={percent}
          css={progressBar}
        />
        <Typography variant="subtitle2">
          {`${daysRemaining} days left`}
        </Typography>
      </div>
    </Card>
  );
};

CampaignPreview.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default CampaignPreview;

import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import {
  getHasAchieved,
  getStaticAchievement,
} from '../redux/achievements';
import { ACHIEVEMENT_ICONS_BY_KEY } from '../utils/achievements';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import indigo from '@material-ui/core/colors/indigo';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';

const achievementCardContainer = css`
  margin-bottom: 16px;
`;

const achievementCard = css`
  width: 100%;
  padding: 16px;
  margin-right: 16px;
  /** In case other achievements in row make cards bigger */
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const achievedCard = css`
  background-color: ${indigo[50]};
`;

const centeredText = css`
  text-align: center;
`;

const iconImage = css`
  width: 32px;
  height: 32px;
`;

const grayscaleImage = css`
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
`;

const AchievementCard = ({
  achievementKey,
}) => {
  const hasAchieved = useSelector(getHasAchieved(achievementKey));
  const staticAchievement = useSelector(getStaticAchievement(achievementKey));

  const name = get(staticAchievement, 'name');
  const description = get(staticAchievement, 'description');
  const icon = get(ACHIEVEMENT_ICONS_BY_KEY, achievementKey);
  const textColor = hasAchieved ? "primary" : "textSecondary";
  
  return (
    <Grid container item xs={3} css={achievementCardContainer}>
      <Tooltip title={description}>
        <Card css={[achievementCard, hasAchieved ? achievedCard : null]}>
          <Typography variant="body1" color={textColor} css={centeredText}>
            {name}
          </Typography>
          {icon ? (
            <div css={hasAchieved ? centeredText : [centeredText, grayscaleImage]}>
              <img css={iconImage} src={icon} />
            </div>
          ) : null}
        </Card>
      </Tooltip>
    </Grid>
  );
}

export default AchievementCard;

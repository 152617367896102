import React, { useEffect, useState } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import {
  getCampaignRatingByCampaignId,
} from '../api/campaignRatings';
import withAuth from '../hoc/withAuth';
import StandardLayout from '../layout/StandardLayout';
import CampaignRatingInfo from '../components/CampaignRatingInfo';
import CampaignRatingForm from '../components/CampaignRatingForm';
import {
  getHasLoaded,
  getIsLoading,
  getCampaignRating,
  setHasLoaded,
  setIsLoading,
  setCampaignRating,
  setCampaignRatingTemplate,
} from '../redux/campaignRatings';

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core';
import formStyles from '../styles/form';

const dontTakeUpEntireLine = css`
  display: flex;
`;

const RateCampaignPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/ratings/:id');
  const campaignId = match.params.id;
  const hasLoaded = useSelector(getHasLoaded);
  const isLoading = useSelector(getIsLoading);
  const campaignRating = useSelector(getCampaignRating(campaignId));

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchCampaignRating() {
      dispatch(setIsLoading(true))
      const campaignRatingAndTemplate = await getCampaignRatingByCampaignId(campaignId);
      if (get(campaignRatingAndTemplate, 'error') === true) {
        setIsError(true);
      } else {
        const fetchedCampaignRating = get(campaignRatingAndTemplate, 'campaignRating');
        const fetchedTemplate = get(campaignRatingAndTemplate, 'template');
        dispatch(setCampaignRating(campaignId, fetchedCampaignRating));
        dispatch(setCampaignRatingTemplate(fetchedTemplate));
        dispatch(setIsLoading(false));
      }
      dispatch(setHasLoaded(true));
    }
    fetchCampaignRating();
  }, [campaignId]);

  if (!hasLoaded) {
    return (
      <StandardLayout>
        <Typography>Loading...</Typography>
      </StandardLayout>
    )
  }

  if (isError) {
    return (
      <StandardLayout>
        <Typography>
          You do not have permission to rate this campaign!
        </Typography>
      </StandardLayout>
    )
  }

  return (
    <StandardLayout>
      <Typography variant="h4" css={formStyles.field}>
        Funder survey
      </Typography>
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <div css={formStyles.field}>
            <Typography>
              Dackie runs surveys after successful campaigns so that creators are recognized for their efforts after the campaign ends.
              You can view the campaign that you funded here:
            </Typography>
            <div css={dontTakeUpEntireLine}>
              <RRLink to={`/campaign/${campaignId}`}>
                <Typography>To campaign</Typography>
              </RRLink>
            </div>
          </div>
          {campaignRating === null ? (
            <CampaignRatingForm campaignId={campaignId} />
          ) : (
              <CampaignRatingInfo />
          )}
        </>
      )}
    </StandardLayout>
  );
};

export default withAuth(RateCampaignPage);

import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import StandardLayout from '../layout/StandardLayout';
import { getCampaignById } from '../api/campaigns';
import CampaignFundTable from '../components/CampaignFundTable';
import CampaignInfo from '../components/CampaignInfo';
import CampaignStatusCard from '../components/CampaignStatusCard';
import MyFundsQuickView from '../components/MyFundsQuickView';
import {
  getIsLoading,
  getCampaign,
  setIsLoading,
  setCampaign,
} from '../redux/campaigns';

import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import formStyles from '../styles/form';

const CampaignPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/campaign/:id');
  const campaignId = match.params.id;
  const isLoading = useSelector(getIsLoading);
  const campaign = useSelector(getCampaign(campaignId))

  useEffect(() => {
    async function fetchCampaign() {
      dispatch(setIsLoading(true))
      const fetchedCampaign = await getCampaignById(campaignId, {
        withUserFunds: true,
      });
      dispatch(setCampaign(fetchedCampaign));
      dispatch(setIsLoading(false));
    }
    if (!campaign) {
      fetchCampaign();
    }
  }, [campaignId]);

  const name = get(campaign, 'name');
  const status = get(campaign, 'status');
  const userFunds = get(campaign, 'userFunds');
  const userAlreadyFundedCampaign = !isEmpty(userFunds);

  if (isLoading) {
    return (
      <StandardLayout>
        <Typography>Loading...</Typography>
      </StandardLayout>
    );
  }

  if (!isLoading && !campaign) {
    return null;
  }

  return (
    <StandardLayout>
      <Typography variant="h4" css={formStyles.field}>
        {name}
      </Typography>
      {['published', 'archived'].includes(status) ? (
        <CampaignStatusCard
          campaignId={campaignId}
          cssProp={formStyles.field}
        />
      ) : null}
      <CampaignInfo
        campaignId={campaignId}
        cssProp={formStyles.field}
      />
      {userAlreadyFundedCampaign ? (
        <MyFundsQuickView
          userFunds={userFunds}
        />
      ) : null}
      <CampaignFundTable
        campaignId={campaignId}
      />
    </StandardLayout>
  );
};

export default CampaignPage;

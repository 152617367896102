import React from 'react';
import get from 'lodash/get';

import { getCampaignFundsHandler } from '../api/campaigns';
import ItemsTable from '../components/ItemsTable';
import {
  getCampaignFundsListHandler,
  setCampaignFundsHandler,
  getCampaignFund,
  setIsLoadingCampaignFunds,
  getIsLoadingCampaignFunds,
} from '../redux/campaignFunds';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';

const FIELDS_TO_SHOW = [
  'Date',
  'Amount',
];

const formatFundAmount = (fund) => {
  const amount = get(fund, 'amount');
  return `$${amount.toFixed(2)}`;
}

const formatFundDate = (fund) => {
  const timestamp = get(fund, 'timestamp');
  return `${new Date(timestamp).toLocaleDateString()}`;
}

const fieldProcessingFuncs = {
  'Amount': formatFundAmount,
  'Date': formatFundDate,
}

const CampaignFundTable = ({
  campaignId,
}) => {
  return (
    <ItemsTable
      titleText="Funds"
      titleVariant="h5"
      shouldNumberRows={false}
      fetchItemsFunc={getCampaignFundsHandler(campaignId)}
      getItemIdsSelector={getCampaignFundsListHandler(campaignId)}
      setItemsDispatch={setCampaignFundsHandler(campaignId)}
      getItemByIdSelector={getCampaignFund}
      setIsLoadingDispatch={setIsLoadingCampaignFunds}
      getIsLoadingSelector={getIsLoadingCampaignFunds}
      fieldsToShow={FIELDS_TO_SHOW}
      fieldProcessingFuncs={fieldProcessingFuncs}
      pageSize={10}
      emptyTableMessage="No funds yet. Be the first to fund!"
    />
  );
};

export default CampaignFundTable;

import React, { useState } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import size from 'lodash/size';

import {
  createCampaignRating,
} from '../api/campaignRatings';
import {
  getCampaignRatingTemplate,
  getCampaignRatingForm,
} from '../redux/campaignRatings';
import CampaignRatingField from '../components/CampaignRatingField';

import Button from '@material-ui/core/Button';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';

const CampaignRatingForm = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/ratings/:id');
  const campaignId = match.params.id;
  const form = useSelector(getCampaignRatingForm(campaignId));
  const template = useSelector(getCampaignRatingTemplate);

  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const formValues = map(form, (data) => get(data, 'value'));
  const validFormValues = filter(formValues, (value) => value || value === 0 || value === false)
  const isSubmitEnabled = size(validFormValues) === size(template);

  const onClickSubmit = async () => {
    setIsLoading(true);
    const readyToSubmitForm = reduce(form, (result, data, key) => {
      const value = get(data, 'value');
      return {
        ...result,
        [key]: value,
      }
    }, {});
    console.log(readyToSubmitForm);
    await createCampaignRating(campaignId, readyToSubmitForm);
    setRedirect(true);
  }

  if (redirect) {
    return (
      <Redirect to="/campaigns/funded" />
    )
  }

  return (
    <>
      {map(template, (fieldData, key) => {
        return (
          <CampaignRatingField
            key={key}
            campaignId={campaignId}
            dataKey={key}
            isLoading={isLoading}
          />
        );
      })}
      <Button
        variant="outlined"
        onClick={onClickSubmit}
        disabled={!isSubmitEnabled}
      >
        Submit
      </Button>
    </>
  );
};

export default CampaignRatingForm;

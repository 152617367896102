import React from 'react';
import { useSelector } from 'react-redux';
import {
  getStripeOnboardingLink,
  getStripeExternalAccountsList,
} from '../redux/stripe';
import ExternalAccount from './ExternalAccount';
import ExternalCardWidget from './ExternalCardWidget';
import StripeBalanceWidget from './StripeBalanceWidget';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const StripeProfile = ({
  isLoading,
  cssProp
}) => {
  const onboardingLink = useSelector(getStripeOnboardingLink);
  const externalAccountsList = useSelector(getStripeExternalAccountsList);

  const renderAccountOnboardingButton = () => {
    return (
      <Button
        variant="outlined"
        onClick={() => window.location = onboardingLink}
      >
        {'Complete Stripe verification'}
      </Button>
    );
  }

  return (
    <div css={[cssProp]}>
      {!isLoading ? (
        <>
          <div css={pageStyles.item}>
            <div>
              {onboardingLink ? (
                <>
                  <Typography>
                    In order to collect money from Dackie (as a creator of a successful campaign or a backer of an unsuccessful campaign), you must verify your identity.
                  </Typography>
                  <Typography>
                    There are still some steps you need to take to verify your identity.
                  </Typography>
                  <Typography>
                    Click this link to go to an external Stripe page that will collect and process your information:
                  </Typography>
                  {renderAccountOnboardingButton()}
                </>
              ) : (
                <>
                  <Typography>
                    Your Stripe account is all ready to make payments!
                  </Typography>
                </>
              )}
            </div>
            {!onboardingLink && !isLoading ? (
              <StripeBalanceWidget />
            ) : null}
          </div>
          <div>
            {externalAccountsList.length > 0 ? (
              <>
                <Typography>
                  You have connected the following payout accounts through Stripe:
                </Typography>
                {externalAccountsList.map(id => {
                  return <ExternalAccount key={id} id={id} />
                })}
              </>
            ) : (
              <>
                <Typography>
                  You don't have any external accounts connected!
                </Typography>
                <Typography>
                  You'll need an external debit card to collect payments.
                </Typography>
              </>
            )}
            <ExternalCardWidget />
          </div>
        </>
      ) : (
          <Typography>
            Loading Stripe data...
          </Typography>
        )}
    </div>
  );
}

export default StripeProfile;

/*
Your account for funding campaigns
Your account for receiving payments and payouts
List of external accounts, with default
Add new bank account
Add new payout card
*/

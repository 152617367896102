import get from 'lodash/get';

const SET_IS_LOADING = 'STRIPE/SET_IS_LOADING';
const SET_ACCOUNT_ID = 'STRIPE/SET_ACCOUNT_ID';
const SET_ONBOARDING_LINK = 'STRIPE/SET_ONBOARDING_LINK';
const SET_BALANCE = 'STRIPE/SET_BALANCE';
const SET_EXTERNAL_ACCOUNTS = 'STRIPE/SET_EXTERNAL_ACCOUNTS';
const SET_CUSTOMER_ID = 'STRIPE/SET_CUSTOMER_ID';
const SET_PROFILE = 'STRIPE/SET_PROFILE';

const initialState = {
  isLoading: false,
  accountId: null,
  onboardingLink: null,
  balance: null,
  externalAccounts: {},
  externalAccountsList: [],
  customerId: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_ACCOUNT_ID:
      return {
        ...state,
        accountId: payload,
      }
    case SET_ONBOARDING_LINK:
      return {
        ...state,
        onboardingLink: payload,
      }
    case SET_BALANCE:
      return {
        ...state,
        balance: payload,
      }
    case SET_EXTERNAL_ACCOUNTS:
      return {
        ...state,
        externalAccounts: payload.externalAccounts,
        externalAccountsList: payload.externalAccountsList,
      }
    case SET_CUSTOMER_ID:
      return {
        ...state,
        customerId: payload,
      }
    case SET_PROFILE:
      return {
        ...state,
        ...payload,
      }
    default:
      return state;
  }
}

export function setIsLoadingStripe(isLoading) {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}

export function setStripeAccountId(accountId) {
  return {
    type: SET_ACCOUNT_ID,
    payload: accountId,
  }
}

export function setStripeOnboardingLink(onboardingLink) {
  return {
    type: SET_ONBOARDING_LINK,
    payload: onboardingLink,
  }
}

export function setStripeBalance(balance) {
  return {
    type: SET_BALANCE,
    payload: balance,
  }
}

export function setStripeExternalAccounts(externalAccountsData) {
  const externalAccountsList = externalAccountsData.map(account => account.id);
  const externalAccounts = externalAccountsData.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
  return {
    type: SET_EXTERNAL_ACCOUNTS,
    payload: {
      externalAccountsList,
      externalAccounts,
    }
  }
}

export function setStripeProfile(data) {
  const accountId = get(data, 'accountId', null);
  const onboardingLink = get(data, 'onboardingLink', null);
  const balance = get(data, 'balance', 0);
  const externalAccountsData = get(data, 'externalAccountsData', []);
  const externalAccountsList = externalAccountsData.map(account => account.id);
  const externalAccounts = externalAccountsData.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
  const customerId = get(data, 'customerId', null);
  return {
    type: SET_PROFILE,
    payload: {
      accountId,
      onboardingLink,
      balance,
      externalAccounts,
      externalAccountsList,
      customerId,
    }
  }
}

export function setStripeCustomerId(customerId) {
  return {
    type: SET_CUSTOMER_ID,
    payload: customerId,
  }
}

export function getIsLoadingStripe(state) {
  return state.stripe.isLoading;
}

export function getStripeAccountId(state) {
  return state.stripe.accountId;
}

export function getStripeOnboardingLink(state) {
  return state.stripe.onboardingLink;
}

export function getStripeBalance(state) {
  return state.stripe.balance;
}

export function getStripeExternalAccount(id) {
  return (state) => state.stripe.externalAccounts[id];
}

export function getStripeExternalAccountsList(state) {
  return state.stripe.externalAccountsList;
}

export function getStripeCustomerId(state) {
  return state.stripe.customerId;
}

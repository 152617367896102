import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import ItemsTableRow from '../components/ItemsTableRow';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core';
import pageStyles from '../styles/page';

const headerContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const prevNextContainer = css`
  flex: 1;
  margin-left: 10px;
`;

const ItemsTable = ({
  titleText,
  titleVariant,
  subtitleText,
  shouldNumberRows,
  fetchItemsFunc,
  getItemIdsSelector,
  getItemByIdSelector,
  setItemsDispatch,
  setIsLoadingDispatch,
  getIsLoadingSelector,
  fieldsToShow,
  fieldProcessingFuncs,
  fieldLinks,
  actionButtons,
  pageSize,
  emptyTableMessage,
  emptyTableButtonText,
  emptyTableButtonHref,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [shouldReloadPage, setShouldReloadPage] = useState(null);
  const isLoading = useSelector(getIsLoadingSelector(page));
  const currentlyShownItemIds = useSelector(getItemIdsSelector(page));
  const hasItems = currentlyShownItemIds && currentlyShownItemIds.length > 0;

  const isPrevEnabled = page > 0;
  const isNextEnabled = currentlyShownItemIds && currentlyShownItemIds.length === pageSize;

  useEffect(() => {
    const fetchItems = async () => {
      const offset = page * pageSize;
      const fetchedItems = await fetchItemsFunc(offset, pageSize);
      dispatch(setItemsDispatch(page, fetchedItems));
      dispatch(setIsLoadingDispatch(page, false));
      setHasLoaded(true);
    }
    fetchItems();
  }, [page, shouldReloadPage]);

  const onClickPrev = () => {
    if (isPrevEnabled) {
      dispatch(setIsLoadingDispatch(page - 1, true));
      setPage(page - 1);
    }
  }

  const onClickNext = () => {
    if (isNextEnabled) {
      dispatch(setIsLoadingDispatch(page + 1, true));
      setPage(page + 1);
    }
  }

  if (!hasLoaded) {
    return null;
  }

  return (
    <>
      <div css={headerContainer}>
        <Typography variant={titleVariant}>
          {titleText}
        </Typography>
        <div css={prevNextContainer}>
          <Button disabled={!isPrevEnabled} onClick={onClickPrev}>
            <ChevronLeftIcon
              fontSize="large"
            />
          </Button>
          <Button disabled={!isNextEnabled} onClick={onClickNext}>
            <ChevronRightIcon
              fontSize="large"
            />
          </Button>
        </div>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : null}
      </div>
      {subtitleText ? (
        <div css={pageStyles.title}>
          <Typography>
            {subtitleText}
          </Typography>
        </div>
      ) : null}
      {hasItems || isLoading ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {shouldNumberRows ? (
                  <TableCell>{'#'}</TableCell>
                ) : null}
                {fieldsToShow.map(field => (
                  <TableCell key={field}>{field}</TableCell>
                ))}
                {actionButtons ? actionButtons.map((actionButton, i) => {
                  const fieldKey = get(actionButton, 'fieldKey');
                  return (
                    <TableCell key={`${fieldKey}-${i}`}>
                      {fieldKey}
                    </TableCell>
                  )
                }) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && currentlyShownItemIds && currentlyShownItemIds.map((id, i) => {
                const index = (page * pageSize) + i;
                return (
                  <ItemsTableRow
                    key={id}
                    id={id}
                    index={index + 1}
                    shouldNumberRows={shouldNumberRows}
                    getItemByIdSelector={getItemByIdSelector}
                    fieldsToShow={fieldsToShow}
                    fieldLinks={fieldLinks}
                    fieldProcessingFuncs={fieldProcessingFuncs}
                    actionButtons={actionButtons}
                    reloadPageCallback={() => {
                      dispatch(setIsLoadingDispatch(page, true))
                      setShouldReloadPage(!shouldReloadPage)
                    }}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {emptyTableMessage ? (
            <div css={pageStyles.item}>
              <Typography>
                {emptyTableMessage}
              </Typography>
            </div>
          ) : null}
          {emptyTableButtonText ? (
            <Button
              variant="outlined"
              href={emptyTableButtonHref}
            >
              {emptyTableButtonText}
            </Button>
          ) : null}
        </>
      )}
    </>
  );
}

ItemsTable.defaultProps = {
  titleVariant: "h4",
  shouldNumberRows: true,
  pageSize: 10,
  fieldsToShow: ['name'],
  fieldProcessingFuncs: {},
  actionButtons: [],
}

export default ItemsTable;

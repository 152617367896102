import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  getAlert,
  setAlert,
} from '../redux/alerts';

import Snackbar from '@material-ui/core/Snackbar';

const DEFAULT_ALERT_DURATION_MS = 4000;

// Expected behavior:
// Setting an alert in Redux will display a Snackbar
// withAlert handles turning off the Snackbar
// Setting another alert before withAlert has turned off the Snackbar changes the message
const withAlert = Component => props => {
  const dispatch = useDispatch();
  const alert = useSelector(getAlert);
  const message = get(alert, 'message');
  const duration = get(alert, 'duration', DEFAULT_ALERT_DURATION_MS);

  const [shownMessage, setShownMessage] = useState(null)

  useEffect(() => {
    setShownMessage(message);
  }, [alert]);

  const onClose = () => {
    if (shownMessage === message) {
      dispatch(setAlert(null, null, null, null));
    }
  }

  return (
    <>
      <Snackbar
        open={!!message}
        autoHideDuration={duration}
        onClose={onClose}
        message={message}
      />
      <Component {...props} />
    </>
  );
}

export default withAlert;

import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsLoading,
  setCampaign,
  getIsLoading,
} from '../redux/draftCampaign';
import get from 'lodash/get';
import { getCampaignForEditing } from '../api/campaigns';

const withDraftCampaign = Component => props => {
  const dispatch = useDispatch();
  const id = get(props, 'match.params.id');
  const currentPageStub = get(props, 'match.path').split('/:id')[0]
  const [redirect, setRedirect] = useState(null);
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    const fetchCampaign = async () => {
      dispatch(setIsLoading(true));
      if (id) {
        const campaign = await getCampaignForEditing(id);
        const status = get(campaign, 'status');
        if ((status === undefined || status === 'edit') && currentPageStub !== '/campaigns/edit') {
          setRedirect(`/campaigns/edit/${id}`);
        } else if (status === 'review' && currentPageStub !== '/campaigns/review') {
          setRedirect(`/campaigns/review/${id}`);
        } else if (status === 'approved' && currentPageStub !== '/campaigns/payment') {
          setRedirect(`/campaigns/payment/${id}`)
        } else if (status === 'published' || status === 'archived') {
          setRedirect(`/campaign/${id}`)
        } else {
          // You're on the right page
          dispatch(setCampaign(campaign));
          dispatch(setIsLoading(false));
        }
      } else {
        dispatch(setIsLoading(false));
      }
    }
    fetchCampaign();
  }, [id]); // redirect too?

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }

  if (isLoading) {
    return null;
  }

  return <Component {...props} />
}

export default withDraftCampaign;

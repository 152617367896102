import map from 'lodash/map';
import reduce from 'lodash/reduce';

const SET_IS_LOADING = 'USER_FUNDS/SET_IS_LOADING';
const SET_USER_FUNDS = 'USER_FUNDS/SET_USER_FUNDS';

const initialState = {
  isLoadingPages: {},
  userFundsLists: {},
  userFundsData: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoadingPages: {
          ...state.isLoadingPages,
          [payload.page]: payload.isLoading,
        },
      }
    case SET_USER_FUNDS:
      return {
        ...state,
        userFundsLists: {
          ...state.userFundsLists,
          [payload.page]: payload.userFundsList,
        },
        userFundsData: {
          ...state.userFundsData,
          ...payload.userFundsData,
        }
      }
    default:
      return state;
  }
}

export const setIsLoadingUserFunds = (page, isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: {
      page,
      isLoading,
    },
  }
}

export const setUserFunds = (page, userFunds) => {
  const userFundsList = map(userFunds, funds => funds.id);
  const userFundsData = reduce(userFunds, (result, fund) => ({ ...result, [fund.id]: fund }), {})
  return {
    type: SET_USER_FUNDS,
    payload: {
      page,
      userFundsList,
      userFundsData,
    },
  }
}

export const getIsLoadingUserFunds = (page) => (state) => {
  return state.userFunds.isLoadingPages[page];
}

export const getUserFundsList = (page) => (state) => {
  return state.userFunds.userFundsLists[page];
}

export const getUserFund = (id) => (state) => {
  return state.userFunds.userFundsData[id];
}

